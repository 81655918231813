import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Button, Typography } from 'antd';
import { DateTime as dt } from 'luxon';
import { getLang } from '../../config/helpers';
import TEXTS from './_texts/CalendarBody.texts';
import './styles.css';

const { Text } = Typography;
const lang = getLang();

const formatEventName = (date) => {
    const d = new Date(date);
    const hours = d.getHours() > 9 ? d.getHours() : `0${d.getHours()}`;
    const minutes = d.getMinutes() > 9 ? d.getMinutes() : `0${d.getMinutes()}`;
    return `${hours}:${minutes}`;
};


const eventRender = (event, handleClickEvent) => (
    <Button
        type="primary"
        className="calendar-event-button"
        onClick={() => { handleClickEvent(event); }}>
        {formatEventName(event.date)}
    </Button>
);


const MapEvents = ({
    events, expandCalendar, handleClickEvent, doctor,
}) => {
    if (events.length > 0) {
        if (expandCalendar) {
            return (
                events.map((ev, j) => (
                    <React.Fragment key={j.toString()}>
                        {eventRender({ ...ev, doctor }, handleClickEvent)}
                    </React.Fragment>
                ))
            );
        }
        return (
            events.slice(0, 4).map((ev, j) => (
                <React.Fragment key={j.toString()}>
                    {eventRender({ ...ev, doctor }, handleClickEvent)}
                </React.Fragment>
            ))
        );
    }
    return (
        <Button
            type="primary"
            className="calendar-event-button hidden-lg show-sm"
            disabled
        >
            {TEXTS.emptyDayResp[lang]}
        </Button>
    );
};

MapEvents.propTypes = {
    events: PropTypes.arrayOf(PropTypes.shape({
        _id: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
    })).isRequired,
    doctor: PropTypes.object.isRequired,
    expandCalendar: PropTypes.bool.isRequired,
    handleClickEvent: PropTypes.func.isRequired,
};


const CalendarBody = ({
    dates, weekDays, handleClickEvent, expandCalendar, doctor, emptyEvents,
}) => (
    <div style={{ margin: '0 7%', padding: '0px 16px', minHeight: 156 }} className="week week-events">

        {emptyEvents ? (
            <div className="no-events">
                <Text strong>
                    {TEXTS.emptyEvents[lang]}
                </Text>
            </div>
        )
            : dates.map((e, i) => (
                <div className={`day-${i} row day `} key={i.toString()}>
                    <div className="col-12 hidden-lg day-row show-sm">
                        <b>
                            {dt
                                .fromJSDate(e)
                                .setLocale(lang)
                                .toFormat('EEEE')
                                .toUpperCase()}
                        </b>
                        <br />
                        <b>
                            {dt
                                .fromJSDate(e)
                                .setLocale(lang)
                                .toLocaleString({ day: 'numeric', month: 'long' })
                                .toUpperCase()}
                        </b>
                    </div>
                    <div
                        className={`col-12 event-list-parent ${moment().format('L')
                            === moment(e).format('L') && 'today'}`}
                    >
                        <div className="row event-list">
                            <MapEvents
                                doctor={doctor}
                                events={weekDays[i].events}
                                handleClickEvent={handleClickEvent}
                                expandCalendar={expandCalendar} />
                        </div>
                    </div>
                </div>
            ))
        }

    </div>
);


CalendarBody.propTypes = {
    dates: PropTypes.array.isRequired,
    weekDays: PropTypes.array.isRequired,
    handleClickEvent: PropTypes.func.isRequired,
    expandCalendar: PropTypes.bool.isRequired,
    doctor: PropTypes.object.isRequired,
    emptyEvents: PropTypes.bool,
};

CalendarBody.defaultProps = {
    emptyEvents: false,
};

export default CalendarBody;
