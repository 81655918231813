

export const spokenLanguages = {
    fr: {
        fr: 'Français',
        en: 'French',
    },
    en: {
        fr: 'Anglais',
        en: 'English',
    },
    es: {
        fr: 'Espagnol',
        en: 'Spanish',
    },
    ar: {
        fr: 'Arabe',
        en: 'Arabic',
    },
    mr: {
        fr: 'Mandarin',
        title: 'Mandarin Chinese',
    },
    gr: {
        fr: 'Allemand',
        en: 'German',
    },
    jp: {
        fr: 'Japonais',
        en: 'Japanese', //
    },
    tc: {
        fr: 'Turc',
        en: 'Turkish',
    },
    pt: {
        fr: 'Portugais',
        en: 'Portuguese',
    },
    ru: {
        fr: 'Russe',
        en: 'Russian',
    },
    it: {
        fr: 'Italien',
        en: 'Italien',
    },

};


export default {
    fr: [
        { key: 'en', title: 'Anglais' },
        { key: 'fr', title: 'Français' },
        { key: 'es', title: 'Espagnol' },
        { key: 'ar', title: 'Arabe' },
        { key: 'mr', title: 'Mandarin' },
        { key: 'gr', title: 'Allemand' },
        { key: 'jp', title: 'Japonais' },
        { key: 'tc', title: 'Turc' },
        { key: 'pt', title: 'Portugais' },
        { key: 'ru', title: 'Russe' },
        { key: 'it', title: 'Italien' },
    ],
    en: [
        { key: 'en', title: 'English' },
        { key: 'fr', title: 'French' },
        { key: 'es', title: 'Spanish' },
        { key: 'ar', title: 'Arabic' },
        { key: 'mr', title: 'Mandarin Chinese' },
        { key: 'gr', title: 'German' },
        { key: 'jp', title: 'Japanese' },
        { key: 'tc', title: 'Turkish' },
        { key: 'pt', title: 'Portuguese' },
        { key: 'ru', title: 'Russian' },
        { key: 'it', title: 'Italien' },
    ],
};
