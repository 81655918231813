/* eslint-disable max-len */
/* eslint-disable prefer-promise-reject-errors */
import React, { useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
    Form, Input, Checkbox, Button,
} from 'antd';
import {
    MailOutlined,
    LockOutlined,
} from '@ant-design/icons';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { signUpAsync } from '../../redux/actions/auth';
import { getLang } from '../../config/helpers';
import TEXTS from './_texts/Signup.texts';
import CGU from '../../components/CGU';
import FORM_RULES from '../../helpers/formRules';
import './styles.css';
import PhoneNumberField from '../../components/PhoneNumberField';

const lang = getLang();


const FrancePrefixNumber = '+33';

const SignupForm = ({ signUpAsync }) => {
    const [form] = Form.useForm();
    const [cguOpen, setCguOpen] = useState(false);
    const buttonToLink = {
        fontSize: '1em',
        textAlign: 'left',
        color: 'blue',
        background: 'none',
        margin: 0,
        padding: 0,
        border: 'none',
        cursor: 'pointer',
    };

    const onFinish = (values) => {
        const body = { ...values, phoneNumber: `${values.prefix} ${values.phone}` };
        signUpAsync(body);
    };

    useLayoutEffect(() => {
        if (window) {
            window.scrollTo({ top: 100, behavior: 'smooth' });
        }
    });

    return (
        <Form
            id="signup-form"
            form={form}
            size="large"
            name="register"
            className="login-form"
            onFinish={onFinish}
            initialValues={{
                prefix: FrancePrefixNumber,
            }}
            scrollToFirstError
        >


            <Form.Item
                name="email"
                rules={[FORM_RULES.email, FORM_RULES.required]}
            >
                <Input
                    style={{ width: '100%' }}
                    prefix={<MailOutlined className="site-form-item-icon" />}
                    placeholder="Email"
                />
            </Form.Item>

            <Form.Item
                name="password"
                rules={[
                    FORM_RULES.required,
                    {
                        validator: (_, value) => (value && value.length > 5
                            ? Promise.resolve()
                            : Promise.reject(
                                TEXTS.inputPasswordMinLength[lang],
                            )),
                    },
                ]}
                hasFeedback
            >
                <Input.Password
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    placeholder={TEXTS.inputPassword[lang]}
                />
            </Form.Item>

            <Form.Item
                name="confirm"
                dependencies={['password']}
                hasFeedback
                rules={[
                    FORM_RULES.required,
                    ({ getFieldValue }) => ({
                        validator(_, value) {
                            if (!value || getFieldValue('password') === value) {
                                return Promise.resolve();
                            }
                            return Promise.reject(TEXTS.confirmPasswordMatchError[lang]);
                        },
                    }),
                ]}
            >
                <Input.Password
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    placeholder={TEXTS.confirmPassword[lang]}
                />
            </Form.Item>

            <PhoneNumberField />

            <Form.Item
                name="agreement"
                valuePropName="checked"
                rules={[
                    {
                        validator: (_, value) => (value
                            ? Promise.resolve()
                            : Promise.reject(TEXTS.checkboxAgreementRequiredError[lang])),
                    },
                ]}
            >
                <Checkbox>
                    {TEXTS.checkboxAgreementText[lang]}
                    <Button style={buttonToLink} onClick={() => setCguOpen(true)}>{TEXTS.checkboxAgreementButton[lang]}</Button>
                </Checkbox>
            </Form.Item>
            <Form.Item>
                <Button id="submit" block type="primary" htmlType="submit">
                    {TEXTS.buttonSubmit[lang]}
                </Button>
            </Form.Item>
            <CGU open={cguOpen} handleClose={() => setCguOpen(false)} />
        </Form>
    );
};


SignupForm.propTypes = {
    signUpAsync: PropTypes.func.isRequired,
};

const dispatchToProps = dispatch => bindActionCreators(
    {
        signUpAsync,
    },
    dispatch,
);


export default connect(null, dispatchToProps)(SignupForm);
