import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Input, Checkbox } from 'antd';
import DoctorCard from './DoctorCard';
import { getLang } from '../../config/helpers';
import { setPublicEventsAsync, setPublicEvents } from '../../redux/actions/consultations';
import TEXTS from './_texts/index.texts';
import './styles.css';

const lang = getLang();

const { Search } = Input;
const filter = (query, data) => {
    const newQuery = query
        .replace(/[^a-z0-9]/gi, '')
        .split('')
        .join('.*');

    return data.filter((item) => {
        const regex = new RegExp(newQuery, 'i');
        const { speciality } = item;
        return (
            regex.test(item.firstName)
            || regex.test(item.lastName)
            || regex.test(speciality)
        );
    });
};

const DoctorList = ({
    setPublicEventsAsync, setPublicEvents, user, publicEvents,
}) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [eventList, setEventList] = useState(publicEvents);
    const [checked, setCheked] = useState(false);
    const [filtredList, setFiltredList] = useState([]);

    useEffect(() => {
        setPublicEventsAsync();
    }, [user]);

    useEffect(() => () => {
        setPublicEvents([]);
        setEventList([]);
    }, []);

    useEffect(() => {
        const data = filter(searchTerm, publicEvents);
        setEventList(data);
    }, [searchTerm]);

    useEffect(() => {
        if (publicEvents.length && !eventList.length) {
            setEventList(publicEvents);
        }
    }, [publicEvents]);
    useEffect(() => {
        const newList = eventList.filter(event => event.consultations.length != 0);
        setFiltredList(newList);
    }, [checked]);

    return (
        <div className="doctor-list-container">
            <Search
                autoFocus
                size="large"
                name="search-input"
                placeholder={TEXTS.searchFilterText[lang]}
                // eslint-disable-next-line no-console
                onChange={e => setSearchTerm(e.target.value)}
            />
            <div className="CheckboxDoctor">
                <Checkbox onChange={() => setCheked(!checked)}>Voir la liste des medecins disponible</Checkbox>
            </div>
            {!checked ? eventList.map((ev, i) => (
                <DoctorCard key={i.toString()} event={ev} />
            ))
                : filtredList.map((ev, i) => (
                    <DoctorCard key={i.toString()} event={ev} />
                ))
            }
        </div>
    );
};

DoctorList.propTypes = {
    user: PropTypes.object,
    publicEvents: PropTypes.array.isRequired,
    setPublicEventsAsync: PropTypes.func.isRequired,
    setPublicEvents: PropTypes.func.isRequired,
};

DoctorList.defaultProps = {
    user: null,
};

const stateToProps = state => ({
    user: state.auth.user,
    publicEvents: state.consultations.publicEvents,
});

const dispatchToProps = dispatch => bindActionCreators(
    {
        setPublicEventsAsync,
        setPublicEvents,
    },
    dispatch,
);

export default connect(stateToProps, dispatchToProps)(DoctorList);
