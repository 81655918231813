import React, { useEffect, useState } from 'react';
import {
    Form, Input, Button, message,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { updateUserAsync } from '../../redux/actions/auth';

const HealthTab = () => {
    const user = useSelector(state => state.auth.user);
    const [form] = Form.useForm();
    const [, setAllowUpdate] = useState(false);
    const inputWidth = 280;

    const dispatch = useDispatch();

    const handleUpdate = (values) => {
        const { firstName, lastName } = values;
        const doctor = { firstName, lastName };
        dispatch(updateUserAsync({ doctor }));
        setAllowUpdate(false);
        message.success('Modifications effectuées avec succès');
    };

    useEffect(() => {
        if (user.doctor) {
            Object.keys(user.doctor).forEach((key) => {
                form.setFieldsValue({ [key]: user.doctor[key] });
            });
        }
    }, []);

    return (
        <div className="flex">
            <div className=" flex">
                <Form
                    onFinish={handleUpdate}
                    form={form}
                    layout="vertical"
                >
                    <Form.Item
                        label="Nom du médecin traitant"
                        name="lastName"
                        style={{ maxWidth: inputWidth }}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Prénom du médecin traitant"
                        name="firstName"
                        style={{ maxWidth: inputWidth }}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item>
                        <Button
                            htmlType="submit"
                            type="primary"
                            ghost
                            className="center-button"
                        >
                            Mettre à jour
                        </Button>
                    </Form.Item>

                </Form>
            </div>
        </div>


    );
};

export default HealthTab;
