/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react';
import {
    Modal,
    Descriptions,
    Button,
    notification,
} from 'antd';
import history from '../../history';

const PostTelec = () => {
    const [showInvoice] = useState(true);
    if (!history.location.state) {
        return history.replace('/');
    }
    const { telec } = history.location.state;


    const handleClose = () => {
        notification.success({
            message: 'Merci pour votre fidélité',
            description:
        'Vous seriez notifié quand votre médecin vous envoi une préscription, A bientot.',
        });
        history.push('/');
    };

    return (
        <Modal
            onCancel={handleClose}
            footer={(
                <Button onClick={handleClose} type="primary">
                    Fermer
                </Button>
            )}
            visible={showInvoice}
            title="Facture"
        >
            <Descriptions bordered column={1}>
                <Descriptions.Item label="Tarification du médecin">
                    {telec.doctor.pricing}
                    €
                </Descriptions.Item>
            </Descriptions>
        </Modal>
    );
};

export default PostTelec;
