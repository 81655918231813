// const API_ENDPOINT = 'https://api.aerohealth.ai/v1'; // PROD
// const API_ENDPOINT = 'http://localhost:8080/v1'; // LOCALE
const API_ENDPOINT = 'https://msante-api.katomi.co/v1'; // STAGING

const API_AUTH = `${API_ENDPOINT}/patient`;
const API_DOCTORS = `${API_ENDPOINT}/doctor`;
const API_CONSULTATIONS = `${API_ENDPOINT}/consultations`;
const API_PHOTOS = `${API_ENDPOINT}/photos`;
const API_DOCUMENTS = `${API_ENDPOINT}/documents`;
const API_PRESCRIPTIONS = `${API_ENDPOINT}/prescriptions`;
const API_REPORTS = `${API_ENDPOINT}/reports`;
const API_PURPOSE = `${API_ENDPOINT}/purpose`;


// PROD
// const STRIPE_PUB_KEY = 'pk_live_51HrNTBKlT1Wc2Oi6kqcjwYE6bjUZK2MRNSLZhNp6oqqcWvJf6haq9Rjjnl4tOeWfpDswHeWocSUGdGicBZOsDKPl00PUPuQoSN';
// DEV
const STRIPE_PUB_KEY = 'pk_test_51HrNTBKlT1Wc2Oi6PsN55Py8x8kbdSXHcX6sS5CT7XJ4MVw92dxIDXH987ttV3SWuz3l2kGdyD1ifAaS5Ggno0K000hvMFmsfl';

const API_REQ_HEADER = {
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json',
    },
};


const PEER_ENDPOINT = {
    // host: 'p2p.aerohealth.ai', // PROD
    host: 'msante-p2p.katomi.co', // STAGING
    // host: 'localhost:9000', // LOCAL
    secure: true,
};

export {
    API_ENDPOINT,
    API_AUTH,
    API_CONSULTATIONS,
    API_PHOTOS,
    API_DOCUMENTS,
    API_PRESCRIPTIONS,
    API_REQ_HEADER,
    STRIPE_PUB_KEY,
    PEER_ENDPOINT,
    API_REPORTS,
    API_DOCTORS,
    API_PURPOSE,
};
