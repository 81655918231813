import React from 'react';
import { Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { getNameInitials } from '../../helpers/functions';

const CustomAvatar = (props) => {
    const {
        src, style, user, ...rest
    } = props;
    return (
        <Avatar
            {...rest}
            style={{ flexShrink: 0, backgroundColor: '#5f9bf1', ...style }}
            icon={user ? null : <UserOutlined style={{ color: '#FFF' }} />}
            src={src}
        >
            {user ? getNameInitials(user) : null}
        </Avatar>
    );
};


CustomAvatar.propTypes = {
    src: PropTypes.string,
    style: PropTypes.object,
    user: PropTypes.object,
};

CustomAvatar.defaultProps = {
    src: null,
    style: {},
    user: null,
};


export default CustomAvatar;
