export default {
    title1: {
        fr: 'Vous avez',
        en: 'You have',
    },
    title2Plural: {
        fr: 'téléconsultations programmées',
        en: 'upcoming teleconsultations',
    },
    title2Singular: {
        fr: 'téléconsultation programmée',
        en: 'upcoming teleconsultation',
    },
    emptyTitle: {
        fr: "Vous n'avez aucune téléconsultation programmée",
        en: "You don't have any upcoming teleconsultation",
    },
};
