import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
    Divider, Typography, Descriptions, Button,
} from 'antd';
import {
    CreditCardTwoTone,
    MessageTwoTone,
    EuroTwoTone,
    IdcardTwoTone,
    BookTwoTone,
    AppstoreTwoTone,
} from '@ant-design/icons';
import { spokenLanguages } from '../../config/doctorSpecialities';
import socialMediaIcons from '../../components/Icons/socialMedia';
import { getLang } from '../../config/helpers';
import './styles.css';

import TEXTS from './_texts/DoctorCard.texts';

const lang = getLang();
const currency = 'EUR';
const { Text, Paragraph } = Typography;

const DoctorInfo = ({ doctor }) => {
    const [fullDescription, showFullDescription] = useState(false);
    const { pricing, pricingNight, pricingWeekEnd } = doctor;
    return (
        <div className="doctor-card-info">
            <Descriptions bordered style={{ width: '100%' }}>
                <Descriptions.Item
                    span={1}
                    label={(
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <CreditCardTwoTone />
                            <h5 style={{ padding: '8px 14px 0 ' }}>
                                {TEXTS.descriptionPricing1stItem[lang]}
                            </h5>
                        </div>
                    )}
                >
                    {pricing}
                </Descriptions.Item>
            </Descriptions>

            {/* NEW */}
            {pricingNight && (
                <Descriptions bordered style={{ width: '100%' }}>
                    <Descriptions.Item
                        span={3}
                        label={(
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <CreditCardTwoTone />
                                <h5 style={{ padding: '8px 14px 0 ' }}>
                                    {TEXTS.descriptionPricing2stItem[lang]}
                                </h5>
                            </div>
                        )}
                    >
                        {pricingNight}
                    </Descriptions.Item>
                </Descriptions>
            )}
            {pricingWeekEnd && (
                <Descriptions bordered style={{ width: '100%' }}>
                    <Descriptions.Item
                        span={1}
                        label={(
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <CreditCardTwoTone />
                                <h5 style={{ padding: '8px 14px 0 ' }}>
                                    {TEXTS.descriptionPricing3stItem[lang]}
                                </h5>
                            </div>
                        )}
                    >
                        {pricingWeekEnd}
                    </Descriptions.Item>
                </Descriptions>
            )}
            <Descriptions bordered style={{ width: '100%' }}>
                <Descriptions.Item
                    span={1}
                    label={(
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <EuroTwoTone />
                            <h5 style={{ padding: '8px 14px 0 ' }}>
                                {TEXTS.description2ndItem[lang]}
                            </h5>
                        </div>
                    )}
                >
                    {currency}
                </Descriptions.Item>
            </Descriptions>

            <Descriptions bordered style={{ width: '100%', textAlign: 'end' }}>
                <Descriptions.Item
                    span={1}
                    label={(
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <MessageTwoTone />
                            <h5 style={{ padding: '8px 14px 0 ' }}>
                                {TEXTS.description4rdItem[lang]}
                            </h5>
                        </div>
                    )}
                >
                    <div className="doctor-info-item">
                        {doctor.spokenLanguages.map(l => `${spokenLanguages[l][lang]}, `)}
                    </div>
                </Descriptions.Item>
            </Descriptions>
            {fullDescription ? (
                <>
                    {doctor.biography ? (
                        <Descriptions bordered style={{ width: '100%' }}>
                            <Descriptions.Item
                                span={1}
                                label={(
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <IdcardTwoTone />
                                        <h5 style={{ padding: '8px 14px 0 ' }}>
                                            {TEXTS.description5thItem[lang]}
                                        </h5>
                                    </div>
                                )}
                            >
                                <div className="doctor-info-item">{doctor.biography}</div>
                            </Descriptions.Item>
                        </Descriptions>
                    ) : null}

                    {doctor.studiesHistory.length ? (
                        <Descriptions bordered style={{ width: '100%' }}>
                            <Descriptions.Item
                                span={1}
                                label={(
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <BookTwoTone />
                                        <h5 style={{ padding: '8px 14px 0 ' }}>
                                            {TEXTS.description6thItem[lang]}
                                        </h5>
                                    </div>
                                )}
                            >
                                {doctor.studiesHistory.map((s, i) => (
                                    <Paragraph key={i.toString()}>
                                        <Text type="secondary">
                                            <div className="doctor-info-item">
                                                {TEXTS.description6thItemUniversity[lang]}
                                                :&nbsp;
                                            </div>
                                        </Text>
                                        <Text>{s.university}</Text>
                                        <br />
                                        <Text type="secondary">
                                            <div className="doctor-info-item">
                                                {TEXTS.description6thItemDiploma[lang]}
                                                :&nbsp;
                                            </div>
                                        </Text>
                                        <Text>{s.diploma}</Text>
                                        <br />
                                        <Text type="secondary">
                                            {TEXTS.description6thItemYear[lang]}
                                        </Text>
                                        <Text>
                                            <div className="doctor-info-item">{s.year}</div>
                                        </Text>
                                        <Divider />
                                    </Paragraph>
                                ))}
                            </Descriptions.Item>
                        </Descriptions>
                    ) : null}

                    {/* <Descriptions bordered style={{ width: '100%' }}>
                        <Descriptions.Item
                            span={1}
                            label={(
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <AppstoreTwoTone />
                                    <h5 style={{ padding: '8px 14px 0 ' }}>
                                        {TEXTS.description7thItem[lang]}
                                    </h5>
                                </div>
                            )}
                        >
                            {Object.keys(doctor.socialMedia).map(sm => (
                                <>
                                    {doctor.socialMedia[sm].length ? (
                                        <a
                                            href={doctor.socialMedia[sm]}
                                            rel="noreferrer"
                                            target="_blank"
                                        >
                                            <Button
                                                style={{ margin: 5 }}
                                                key={sm}
                                                shape="circle"
                                                icon={socialMediaIcons[sm]}
                                            />
                                        </a>
                                    ) : null}
                                </>
                            ))}
                        </Descriptions.Item>
                    </Descriptions> */}
                </>
            ) : null}
            <Button
                type="primary"
                block
                onClick={() => showFullDescription(!fullDescription)}
            >
                {TEXTS.buttonCheckProfile[lang]}
            </Button>
        </div>
    );
};

DoctorInfo.propTypes = {
    doctor: PropTypes.shape({
        _id: PropTypes.string.isRequired,
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
        pricing: PropTypes.number.isRequired,
        speciality: PropTypes.string.isRequired,
        country: PropTypes.string.isRequired,
        spokenLanguages: PropTypes.array.isRequired,
        studiesHistory: PropTypes.array.isRequired,
        biography: PropTypes.string,
        socialMedia: PropTypes.object,
    }).isRequired,
};

export default DoctorInfo;
