/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    Card, Tag, Button, Modal,
} from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { DateTime } from 'luxon';
import CustomAvatar from '../Avatar';
import { getFullName, parseDateForTelec } from '../../helpers/functions';
import {
    setActiveTelec,
    removeConsultationAsync,
} from '../../redux/actions/consultations';
import { loadProfileImage } from '../../api/images';
import { getLang } from '../../config/helpers';
import TEXTS from './_texts/index.texts';

const lang = getLang();
const { Meta } = Card;

const notStartingNow = (date) => {
    const minutesLeft = DateTime.fromISO(date).diffNow('minutes').values.minutes;
    if (minutesLeft <= 15 && minutesLeft > -60) {
        return false;
    }
    return true;
};

const TelecCard = ({
    telec,
    setActiveTelec,
    removeConsultationAsync,
    hoverable,
    onCancel,
}) => {
    const [startDisabled, setStartDisabled] = useState(notStartingNow(telec.date) || telec.paymentIntent === 'failure');

    // eslint-disable-next-line consistent-return
    useEffect(() => {
        const startingToday = DateTime.fromISO(telec.date).diffNow('hours').values.hours >= -8;
        const paymentFailed = telec.paymentIntent === 'failure';
        if (startDisabled && startingToday && !paymentFailed) {
            const intId = setInterval(() => {
                if (!notStartingNow(telec.date)) {
                    setStartDisabled(false);
                }
            }, 45000); // every 45 seconds
            return () => {
                clearInterval(intId);
            };
        }
    }, [startDisabled]);

    const formattedDate = parseDateForTelec(telec.date);

    const handleCancel = () => {
        Modal.confirm({
            title: TEXTS.cancelTitle[lang],
            icon: <ExclamationCircleOutlined />,
            content: TEXTS.cancelContent[lang],
            okText: TEXTS.cancelOkButton[lang],
            cancelText: TEXTS.cancelCancelButton[lang],
            okType: 'danger',
            onOk: () => {
                removeConsultationAsync(telec._id);
                onCancel();
            },
        });
    };

    return (
        <Card
            id="dashboard-telec-element"
            style={{ margin: 24, opacity: 0.85 }}
            hoverable={hoverable}
            actions={[
                <Button type="danger" onClick={handleCancel}>
                    {TEXTS.actionCancel[lang]}
                </Button>,
                <Button
                    type="primary"
                    ghost
                    disabled={startDisabled}
                    onClick={() => {
                        setActiveTelec(telec);
                        onCancel();
                    }}
                >
                    {TEXTS.actionStart[lang]}
                </Button>,
            ]}
            title={(
                <div className="flex" style={{ alignItems: 'center' }}>
                    <CustomAvatar
                        src={loadProfileImage(telec.doctor._id, 'thumbnail')}
                        size={30}
                        style={{ marginRight: 10 }}
                    />
                    <b>{`Dr. ${getFullName(telec.doctor)}`}</b>
                </div>
            )}
        >
            <Meta title={TEXTS.dateHour[lang]} description={formattedDate} />

            <div className="flex flex-wrap" style={{ marginTop: 10 }}>
                <div className="ant-card-meta-title">{TEXTS.cPurpose[lang]}</div>

                {telec.cPurposes.length
                    ? telec.cPurposes.map(c => (
                        <Tag key={c} style={{ margin: 5 }} color="processing">
                            {c}
                        </Tag>
                    ))
                    : TEXTS.emptyCPurpose[lang]}
            </div>
        </Card>
    );
};

TelecCard.propTypes = {
    telec: PropTypes.object.isRequired,
    setActiveTelec: PropTypes.func.isRequired,
    removeConsultationAsync: PropTypes.func.isRequired,
    hoverable: PropTypes.bool,
    onCancel: PropTypes.func,
};


TelecCard.defaultProps = {
    hoverable: true,
    onCancel: () => {},
};

const dispatchToProps = dispatch => bindActionCreators(
    {
        setActiveTelec,
        removeConsultationAsync,
    },
    dispatch,
);

export default connect(null, dispatchToProps)(TelecCard);
