/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/media-has-caption */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Elements } from '@stripe/react-stripe-js';
import { bindActionCreators } from 'redux';
import {
    Card,
    Input,
    Button,
    Typography,
    Form,
    Result,
    notification,
    Tooltip,
    Upload,
    message,
} from 'antd';
import {
    UserOutlined,
    PaperClipOutlined,
    PictureOutlined,
} from '@ant-design/icons';
import Avatar from 'antd/lib/avatar/avatar';
import Peer from 'peerjs';
import { loadStripe } from '@stripe/stripe-js';

import {
    setActiveTelec,
    setConsultationsList,
} from '../../redux/actions/consultations';
import { updatePaymentStatus } from '../../api/consultations';
import LocalStreamPreview from './localStreamPreview';
import { STRIPE_PUB_KEY, PEER_ENDPOINT } from '../../config';
import CustomAvatar from '../../components/Avatar';
import { getFullName } from '../../helpers/functions';
import { getLang } from '../../config/helpers';
import { loadProfileImage, addImages } from '../../api/images';
import MediaControls from './mediaControls';
import Messages from './messages';
import PaymentVerification from './PaymentVerification';
import TEXTS from './_texts/index.texts';
import { PlayStore } from '../../components/Icons/cards';
import './styles.css';

const lang = getLang();
const { Title } = Typography;
const stripeLoader = loadStripe(STRIPE_PUB_KEY);

const StartTelec = ({
    user,
    activeTelec,
    setActiveTelec,
    setConsultationsList,
}) => {
    const [canStart, setCanStart] = useState();
    const [peer, setPeer] = useState();
    const [localStream, setLocalStream] = useState(null);
    const [activeConnection, setActiveConnection] = useState(null);
    const [remoteStream, setRemoteStream] = useState(null);
    const [messages, setMessages] = useState([]);
    const [newMsg, setNewMsg] = useState(null);
    const [form] = Form.useForm();
    const [showStripe, setShowStripe] = useState(null);

    const scrollToBottom = () => {
        setTimeout(() => {
            const element = document.getElementById('messages-container');
            if (element) {
                element.scrollTop = element.scrollHeight;
            }
        }, 500);
    };

    const pushMessage = (message, sender) => {
        const content = {
            sender,
            content: message,
            time: new Date(),
        };

        setNewMsg(content);
    };

    const connectPeer = () => {
        const peer = new Peer(`${user._id}-${activeTelec._id}`, PEER_ENDPOINT);
        const remoteId = activeTelec.doctor._id;
        peer.on('open', () => {
            setPeer(peer);
            if (!activeConnection) {
                const conn = peer.connect(`${remoteId}-${activeTelec._id}`);
                conn.on('open', () => {
                    conn.on('data', (data) => {
                        pushMessage(data, remoteId);
                    });
                    const calls = peer.call(
                        `${remoteId}-${activeTelec._id}`,
                        localStream,
                    );
                    calls.on('stream', (stream) => {
                        setRemoteStream(stream);
                    });
                    setActiveConnection(conn);
                });
                conn.on('close', () => setActiveConnection(null));
            }
            peer.on('connection', (conn) => {
                conn.on('close', () => setActiveConnection(null));
                conn.on('data', (data) => {
                    pushMessage(data, remoteId);
                });
                peer.on('call', (call) => {
                    call.answer(localStream);
                    call.on('stream', (stream) => {
                        setRemoteStream(stream);
                    });
                });

                setActiveConnection(conn);
            });
        });
        const videoCaller = document.querySelector('#local-video');
        videoCaller.srcObject = localStream;
        videoCaller.volume = 0;
        if (remoteStream && remoteStream.getVideoTracks().length > 0) {
            const videoReceiver = document.querySelector('#remote-video');
            videoReceiver.srcObject = remoteStream;
        }
    };

    useEffect(() => {
        if (newMsg) {
            const newMessages = [...messages];
            newMessages.push(newMsg);
            setMessages(newMessages);
            scrollToBottom();
        }
    }, [newMsg]);

    useEffect(() => {
        console.log('*********************************');
        console.log('EFFECT', localStream);
        console.log('*********************************');
        if (localStream && localStream.getVideoTracks().length > 0) {
            const videoCaller = document.querySelector('#local-video-preview');
            videoCaller.srcObject = localStream;
            videoCaller.volume = 0;
        }
    }, [localStream]);

    useEffect(() => {
        if (canStart) {
            connectPeer();
        }
    }, [canStart]);

    useEffect(() => {
        console.log('tracks', localStream ? localStream.getTracks() : 'null');
        console.log('VIDEO', localStream ? localStream.getVideoTracks() : 'null');
        if (canStart && remoteStream) {
            localStream.getAudioTracks()[0].enabled = false;
            localStream.getVideoTracks()[0].enabled = false;
            setTimeout(() => {
                setShowStripe(true);
            }, 3000);
        }
    }, [remoteStream]);

    useEffect(() => {
        if (showStripe === false) {
            const videoReceiver = document.querySelector('#remote-video');
            if (videoReceiver) videoReceiver.srcObject = remoteStream;
        }
    }, [showStripe]);

    const handleCheckBox = (option) => {
        if (option === 'mic') {
            localStream.getAudioTracks()[0].enabled = !localStream.getAudioTracks()[0]
                .enabled;
        } else {
            localStream.getVideoTracks()[0].enabled = !localStream.getVideoTracks()[0]
                .enabled;
        }
    };

    const onFinish = ({ messageToSend }) => {
        if (activeConnection && messageToSend.trim().length) {
            pushMessage(messageToSend, user._id);
            activeConnection.send(messageToSend);
            form.resetFields();
        }
    };

    const endCall = async () => {
        localStream.getTracks().forEach(track => track.stop());
        setConsultationsList([]);
        setActiveTelec(null);
        peer.disconnect();
        peer.destroy();
    };

    const uploadImage = async (file) => {
        try {
            const uploadRes = await addImages(
                user._id,
                'photos',
                file,
            );
            const patientImages = uploadRes.data.photos;
            const mostRecentImage = patientImages[patientImages.length - 1];

            message.success(TEXTS.uploadSuccessMSG[lang]);

            onFinish({ messageToSend: `img##${mostRecentImage}` });
        } catch (err) {
            message.error(TEXTS.uploadErrorMSG[lang]);
        }
    };

    if (window.innerWidth <= 600) {
        return (
            <Result
                status="403"
                title={TEXTS.resultTitle[lang]}
                subTitle={TEXTS.resultSubtitle[lang]}
                extra={(
                    <div className="flex-column" style={{ alignItems: 'center' }}>
                        <a
                            href="https://play.google.com/store/apps/details?id=com.katomi.aestheclic"
                            target="_blank"
                            rel="noreferrer"
                            style={{ alignSelf: 'flex-start' }}
                        >
                            <Button size="large" className="googleplay-button">
                                <PlayStore style={{ width: 16, height: 16, marginRight: 5 }} />
                                <div className="flex-column">
                                    <small style={{ fontSize: 11 }}> Get it on </small>
                                    <span style={{ fontSize: 14 }}> Google play </span>
                                </div>
                            </Button>
                        </a>

                        <Button
                            onClick={() => {
                                setActiveTelec(null);
                            }}
                            type="primary"
                        >
                            {TEXTS.backButton[lang]}
                        </Button>
                    </div>
                )}
            />
        );
    }

    const onPaymentAuthSuccess = async () => {
        setShowStripe(false);
        localStream.getAudioTracks()[0].enabled = true;
        localStream.getVideoTracks()[0].enabled = true;
        try {
            await updatePaymentStatus(activeTelec._id, { paymentIntent: 'success' });
        } catch (err) {
            // nada
        }
    };

    const onPaymentError = async (paymentErrCode) => {
        endCall();
        notification.error({
            message: TEXTS.error_payment_message[lang],
            description: TEXTS[paymentErrCode][lang],
        });
        try {
            await updatePaymentStatus(activeTelec._id, { paymentIntent: 'failure' });
        } catch (err) {
            // do nothing
        }
    };
    const { getVideoTracks } = remoteStream || {};
    return (
        <div
            className="layout"
            style={{ minHeight: '100vh', height: 'max-content', overflow: 'auto' }}
        >
            <Elements stripe={stripeLoader}>
                <PaymentVerification
                    showStripe={showStripe}
                    telecId={activeTelec._id}
                    onError={onPaymentError}
                    onOk={onPaymentAuthSuccess}
                />
            </Elements>
            {!canStart ? (
                <LocalStreamPreview
                    handleStart={() => setCanStart(true)}
                    localStream={localStream}
                    setLocalStream={setLocalStream}
                />
            ) : (
                <div className="flex" style={{ height: '100vh' }}>
                    <div style={{ flex: 6 }}>
                        <div className="flex-column">
                            <div
                                style={{
                                    height:
                    localStream && localStream.getVideoTracks().length > 0
                        ? '52vh'
                        : '80vh',
                                    marginBottom: 24,
                                }}
                            >
                                {activeConnection
                && remoteStream
                && remoteStream.getVideoTracks().length > 0 ? (
                    <>
                        <video id="remote-video" autoPlay />
                        <canvas id="canvas" style={{ display: 'none' }} />
                    </>
                                    ) : activeConnection ? (
                                        <div
                                            style={{
                                                display: 'flex',
                                                height: '100%',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <Avatar shape="square" size={256} icon={<UserOutlined />} />
                                        </div>
                                    ) : (
                                        <Title
                                            level={4}
                                            style={{ textAlign: 'center', margin: '16%' }}
                                        >
                                            {TEXTS.interlucatorOffline[lang]}
                                        </Title>
                                    )}
                            </div>
                            <div
                                style={{ height: '35vh', alignItems: 'center' }}
                                className="flex-column"
                            >
                                <video id="local-video" autoPlay />
                                <MediaControls
                                    handleCheckBox={handleCheckBox}
                                    canHangup
                                    onHangup={endCall}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="flex-column" style={{ flex: 2 }}>
                        <Card
                            className="telec-messages-card"
                            title={(
                                <div className="flex" style={{ alignItems: 'center' }}>
                                    <CustomAvatar
                                        src={loadProfileImage(activeTelec.doctor._id, 'thumbnail')}
                                        size={30}
                                        style={{ marginRight: 10 }}
                                    />
                                    <b>{getFullName(activeTelec.doctor)}</b>
                                </div>
                            )}
                            actions={[
                                <div className="flex-column" style={{ margin: '0 1em' }}>
                                    <Form onFinish={onFinish} form={form}>
                                        <div className="message-input-wrapper">
                                            <Form.Item
                                                className="input-formitem"
                                                name="messageToSend"
                                            >
                                                <Input
                                                    autoFocus
                                                    size="large"
                                                    placeholder={TEXTS.messagePlacholder[lang]}
                                                />
                                            </Form.Item>

                                            <div className="input-actions">
                                                <Tooltip title="Charger un document">
                                                    <Button
                                                        type="text"
                                                        size="large"
                                                        icon={<PaperClipOutlined />}
                                                    />
                                                </Tooltip>
                                                <Tooltip title="Charger une image">
                                                    <Upload
                                                        showUploadList={false}
                                                        customRequest={({ file }) => {
                                                            uploadImage(file);
                                                        }}
                                                        listType="text"
                                                    >
                                                        <Button
                                                            type="text"
                                                            size="large"
                                                            icon={<PictureOutlined />}
                                                        />
                                                    </Upload>
                                                </Tooltip>
                                            </div>
                                        </div>
                                        <Form.Item>
                                            <Button
                                                block
                                                type="primary"
                                                htmlType="submit"
                                                disabled={!activeConnection}
                                            >
                                                {TEXTS.sendMessage[lang]}
                                            </Button>
                                        </Form.Item>
                                    </Form>
                                </div>,
                            ]}
                        >
                            <div
                                style={{ height: '75vh', overflow: 'auto' }}
                                id="messages-container"
                            >
                                <Messages messages={messages} />
                            </div>
                        </Card>
                    </div>
                </div>
            )}
        </div>
    );
};

StartTelec.propTypes = {
    user: PropTypes.object.isRequired,
    activeTelec: PropTypes.object.isRequired,
    setActiveTelec: PropTypes.func.isRequired,
    setConsultationsList: PropTypes.func.isRequired,
};

const stateToProps = state => ({
    user: state.auth.user,
    activeTelec: state.consultations.activeTelec,
});

const dispatchToProps = dispatch => bindActionCreators(
    {
        setActiveTelec,
        setConsultationsList,
    },
    dispatch,
);

export default connect(stateToProps, dispatchToProps)(StartTelec);
