export default {
    step0ResultTitle: {
        fr: 'Merci de confirmer votre identité',
        en: 'Please verify your identity',
    },
    step0ResultSubTitle: {
        fr: `Pour des raisons de sécurité, un email vous a été envoyé afin de vérifier votre identité. 
        Merci d'utiliser le lien contenu dans ce dernier afin de vous connecter.`,
        en: `For your account's security, an email was sent to you to confirm your identity.
        Please use the link contained in it in order to connect.`,
    },
    step0StartButton: {
        fr: 'Vérifier',
        en: 'Verify',
    },
};
