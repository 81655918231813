export default {
    prepareButtonLabelCase1: {
        fr: 'Confirmer',
        en: 'I confirm',
    },
    prepareButtonLabelCaseDefault: {
        fr: 'Suivant',
        en: 'Next',
    },

    step0Title: {
        fr: "Le tarif d'une consultation peut être soumis à une majoration après 20h ainsi que le Samedi et le Dimanche",
        en: "A consultation's price may vary if done after 8pm or on the week-end",
    },
    step0PriceDecrease: {
        fr: 'Le prix de la consultation est moins cher que la tarification normale .',
        en: 'The price has been decreased',
    },

    step1Title: {
        fr: 'Confirmez-vous votre rendez-vous avec Dr.',
        en: 'Do you confirm your appointment with Dr.',
    },
    step1SubTitle: {
        fr: 'le',
        en: 'planned for',
    },
    step2Title: {
        fr: 'Veuillez indiquer le motif de votre consultation',
        en: 'Please provide your consultation reason',
    },
    step3Title: {
        fr: 'Veuillez indiquer vos informations de paiement',
        en: 'Please provide your payment informations',
    },
    step4Title: {
        fr: "Permission d'accés a vos documents",
        en: 'Access permission to your documents',
    },
    returnButton: {
        fr: 'Annuler',
        en: 'Cancel',
    },
    sucessBookingDescription: {
        fr: 'Votre téléconsultation a été réservée avec succès. Vous recevrez une notification de rappel le jour de votre rendez-vous.',
        en: 'You have successfully booked this teleconsultation, you will receive a reminder notification on the day of your appointment.',
    },
    sucessBookingMessage: {
        fr: 'Succès',
        en: 'Success',
    },
    bookedErrResultTitle: {
        fr: 'Echec de réservation',
        en: 'Booking failed',
    },
    bookedErrResultSubTitle: {
        fr: 'Ce créneau de téléconsultation a déjà été réservé par un autre utilisateur.',
        en: 'Unfortunately, this time slot is already booked by another user.',
    },
    bookedErrResultExtra: {
        fr: 'Réserver un autre créneau',
        en: 'Book another time slot',
    },

};
