import auth from './auth';
import consultations from './consultations';
import loading from './loading';
import purposes from './purpose';

export default {
    auth,
    consultations,
    loading,
    purposes,
};
