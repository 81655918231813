import axios from 'axios';
import { API_PURPOSE } from '../config';

const getByDoctorId = async (id) => {
    const res = await axios.get(`${API_PURPOSE}/doctor/${id}`, {
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
        },
    });
    return res.data;
};

const getById = async (id) => {
    const res = await axios.get(`${API_PURPOSE}/${id}`, {
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return res.data;
};

const create = async (body) => {
    const res = await axios.post(API_PURPOSE, body, {
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
        },
    });
    return res.data;
};
const remove = async (id) => {
    const res = await axios.delete(`${API_PURPOSE}/${id}`, {
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return res.data;
};

export {
    getById, create, remove, getByDoctorId,
};
