import React, { useState } from 'react';
import { Typography, Divider, Button } from 'antd';
import SignupForm from './Signup';
import LoginForm from './Login';

const { Title } = Typography;

const Auth = () => {
    const [showSignup, setShowSignup] = useState(false);
    return (
        <div className="flex-column">
            <img
                src="ah-logo.svg"
                alt="wtver"
                className="first-image-auth"
            />
            <div className="auth-container flex-column">
                <img
                    src="banner-img3.png"
                    alt="wtver"
                    className="signup-advertising-image"
                />
                <div className="signup-advertising flex-column">
                    <Divider className="signup-divider" />
                    {showSignup ? <SignupForm /> : <LoginForm />}
                    <Title
                        style={{ fontSize: 18, marginBottom: 18, textAlign: 'center' }}
                        level={3}
                    >
                        {showSignup ? 'Déja inscrit?' : 'Pas de compte?'}
                    </Title>

                    <Button
                        type="primary"
                        onClick={() => setShowSignup(!showSignup)}
                        size="large"
                        style={{ margin: 'auto', display: 'block' }}
                    >
                        {showSignup ? 'Déja inscrit? Se connecter' : "S'inscrire maintenant"}
                        {' '}
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default Auth;
