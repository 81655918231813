import React from 'react';
import {
    Form, Input, Select,
} from 'antd';
import { PhoneOutlined } from '@ant-design/icons';
import getDialingCodes from '../../helpers/countries';
import FORM_RULES from '../../helpers/formRules';


const { Option } = Select;

const PhoneNumberField = () => {
    const prefixSelector = (
        <Form.Item rules={[FORM_RULES.required]} name="prefix" noStyle>
            <Select showSearch optionFilterProp="children" style={{ width: 100 }}>
                {Object.values(getDialingCodes()).map((opt, i) => (
                    <Option key={i.toString()} value={`${opt.code}`}>
                        {`${opt.flag} ${opt.code}`}
                    </Option>
                ))}
            </Select>
        </Form.Item>
    );

    return (
        <div>
            <Form.Item name="phone" rules={[FORM_RULES.required]}>
                <Input
                    addonBefore={prefixSelector}
                    prefix={<PhoneOutlined className="site-form-item-icon" />}
                    placeholder="Numéro de téléphone"
                    style={{ width: '100%' }}
                />
            </Form.Item>
        </div>
    );
};

PhoneNumberField.propTypes = {
};


export default (PhoneNumberField);
