import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Button } from 'antd';
import CalendarHeader from './CalendarHeader';
import CalendarBody from './CalendarBody';
import './styles.css';
import { getLang } from '../../config/helpers';
import { selectEvent } from '../../redux/actions/consultations';
import TEXTS from './_texts/index.texts';

const lang = getLang();

class WeekCalendar extends Component {
  state = {
      week: 0,
      dates: [],
      weekDays: [{ events: [] }, { events: [] }, { events: [] }, { events: [] }],
      expandCalendar: false,
  };

  componentDidMount() {
      const { events } = this.props;
      this.setState({ events }, () => this.daysHandler(0));
  }

  componentWillReceiveProps(newProps) {
      const { events } = this.props;
      return (
          newProps.events !== events
      && this.setState({ events: newProps.events }, () => this.daysHandler(0))
      );
  }

  daysHandler = (sum) => {
      const { events, weekDays, week } = this.state;
      const { dateLabel } = this.props;

      const weekNumber = week + sum;

      const startOfWeek = moment()
          .add(weekNumber, 'days')
          .startOf('days');
      const endOfWeek = moment()
          .add(weekNumber === 0 ? 3 : weekNumber + 3, 'days')
          .endOf('days');

      const days = [];
      let day = startOfWeek;

      while (day <= endOfWeek) {
          days.push(day.toDate());
          day = day.clone().add(1, 'd');
      }
      const d = [...weekDays];
      days.map((day, i) => {
          const evts = events.filter(
              e => moment(day).format('L') === moment(e[dateLabel]).format('L'),
          );
          d[i] = { events: evts, name: d[i].name };
          return d[i];
      });

      this.setState({
          week: weekNumber,
          dates: days,
          weekDays: d,
      });
  };

  render() {
      const {
          dates, weekDays, expandCalendar, events,
      } = this.state;
      const { selectEvent, doctor } = this.props;
      const noEvents = events ? events.length === 0 : false;
      return (
          <div id="react-week-calendar" style={{ marginTop: 24 }}>
              <CalendarHeader dates={dates} daysHandler={this.daysHandler} />
              <CalendarBody
                  expandCalendar={expandCalendar}
                  dates={dates}
                  weekDays={weekDays}
                  doctor={doctor}
                  handleClickEvent={selectEvent}
                  emptyEvents={noEvents} // NOTE THIS IS TEMPORARY IT WILL BE REMOVED SOON
              />
              <Button
                  onClick={() => {
                      this.setState({ expandCalendar: !expandCalendar });
                  }}
                  disabled={noEvents}
                  block
                  className="hide-sm"
                  style={{ marginTop: '2.7rem' }}
              >
                  {TEXTS.fullCalendarButton[lang]}
              </Button>
          </div>
      );
  }
}

WeekCalendar.propTypes = {
    events: PropTypes.array.isRequired,
    selectEvent: PropTypes.func.isRequired,
    doctor: PropTypes.object.isRequired,
    dateLabel: PropTypes.string,
};

WeekCalendar.defaultProps = {
    dateLabel: 'date',
};
const dispatchToProps = dispatch => bindActionCreators(
    {
        selectEvent,
    },
    dispatch,
);

export default connect(null, dispatchToProps)(WeekCalendar);
