export default {
    allowAccess: {
        fr: "Veuillez autoriser l'accés a votre caméra/webcam pour commencer",
        en: 'In order to start, you need to authorize the access to your microphone/webcam',
    },
    startTitle: {
        fr: 'Débuter votre téléconsultation avec ',
        en: 'Start your teleconsultation with',
    },
    startButton: {
        fr: 'Commencer',
        en: 'Start',
    },
    authorizeButton: {
        fr: "Autoriser l'accés",
        en: 'Authorize the access',
    },
    notFoundError: {
        fr: 'Micro et caméra non detectés.',
        en: 'Could not detect your microphone/webcam.',
    },
    notAllowedError: {
        fr: "Veuillez autoriser l'accés a votre micro et caméra afin de continuer.",
        en: 'You need to authorize the access to your microphone/webcam in order to continue.',
    },
};
