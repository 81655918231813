export default {
    actionStart: {
        fr: 'Débuter',
        en: 'Start',
    },
    actionCancel: {
        fr: 'Annuler la téléconsultation',
        en: 'Cancel the teleconsultation',
    },
    modalTitle: {
        fr: 'Votre téléconsultation avec',
        en: 'Your teleconsultation with',
    },
    mobileCard: {
        fr: "Utiliser l'application mobile",
        en: 'Use the mobile app',
    },
    desktopCard: {
        fr: 'Utiliser un ordinateur',
        en: 'Use desktop',
    },
    selectDeviceTitle: {
        fr: 'Vous avez une téléconsultation qui commence maintenant',
        en: 'Your teleconsultation is starting now',
    },
    selectedDeviceText: {
        fr: 'Merci de suivre les instructions suivantes afin de démarrer votre téléconsultation',
        en: 'Please follow these instructions to start your teleconsultation',
    },


};
