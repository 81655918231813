import React from 'react';
import PropTypes from 'prop-types';
import { Divider, Typography, Card } from 'antd';
import Calendar from '../../components/Calendar';
import CustomAvatar from '../../components/Avatar';
import { loadProfileImage } from '../../api/images';
import { getFullName } from '../../helpers/functions';
import DoctorInfo from './DoctorInfo';
import './styles.css';

const { Title } = Typography;

const DoctorCard = ({ event }) => {
    const { _id } = event;
    const { events } = event.consultations;

    return (
        <Card
            className="result-card"
        >
            <div className="doctor-card card-div">
                <div className="doctor-card-identity">
                    <CustomAvatar size={80} src={loadProfileImage(_id, 'preview')} />
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            marginLeft: '1rem',
                        }}
                    >
                        <Title level={4}>{`Dr. ${getFullName(event)}`}</Title>
                        <Title
                            level={4}
                            type="secondary"
                            style={{ marginTop: 0, fontSize: 14 }}
                        >
                            {event.speciality}
                        </Title>
                    </div>
                </div>
                <DoctorInfo doctor={event} />
            </div>
            <Divider style={{ height: 'auto' }} type="vertical" className="show-lg" />

            <div className={`card-div responsive-calendar ${events != undefined ? 'null' : 'flexCenter'}`} style={{ height: 'auto', minHeight: '100px' }}>
                { events != undefined ? (
                    <Calendar events={events} doctor={event} />
                )
                    : <strong>Pas de consultation.</strong>
                }
            </div>

        </Card>
    );
};

DoctorCard.propTypes = {
    event: PropTypes.PropTypes.shape({
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
        pricing: PropTypes.number.isRequired,
        consultations: PropTypes.PropTypes.shape({
            doctor: PropTypes.string.isRequired,
            events: PropTypes.shape({
                _id: PropTypes.string.isRequired,
                date: PropTypes.string.isRequired,
            }),
        }).isRequired,
    }).isRequired,
};

export default DoctorCard;
