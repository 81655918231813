/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { DateTime } from 'luxon';
import {
    Button,
    Card,
    Progress,
    Typography,
    notification,
    Result,
    Form,
    Select,
} from 'antd';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from './CheckoutForm';
import { getFullName, parseDateForTelec } from '../../helpers/functions';
import { getLang } from '../../config/helpers';
import {
    selectEvent,
    createSetupIntentAsync,
} from '../../redux/actions/consultations';

import { setCPurposesListAsync } from '../../redux/actions/purpose';
import { grantPermissionAsync } from '../../redux/actions/auth';
import TEXTS from './_texts/index.texts';
import { navigateTo } from '../../history';
import { STRIPE_PUB_KEY } from '../../config';
import ImagePermission from './ImagesPermission';

const lang = getLang();
const { Title } = Typography;

const ConfirmReservation = ({ date }) => (
    <div className="flex-column">
        <Title level={4} className="m-auto">
            {TEXTS.step1SubTitle[lang]}
        </Title>
        <Title style={{ marginTop: '1rem' }} level={4}>
            {parseDateForTelec(date)}
        </Title>
    </div>
);

ConfirmReservation.propTypes = {
    date: PropTypes.object.isRequired,
};

const PricingWarning = ({ pricing, price }) => (
    <div className="flex-column">
        <Title style={{ marginTop: '1rem' }} level={4}>
            Tarif habituel:
            {' '}
            {price}
            {' '}
            €
        </Title>
        <Title style={{ marginTop: '1rem' }} level={4}>
            Tarif de cette consultation :
            {' '}
            {pricing}
            {' '}
            €
        </Title>
    </div>
);

PricingWarning.propTypes = {
    pricing: PropTypes.number.isRequired,
    price: PropTypes.number.isRequired,
};

const prepareButtonLabel = (step) => {
    switch (step) {
        case 1:
            return TEXTS.prepareButtonLabelCase1[lang];
        default:
            return TEXTS.prepareButtonLabelCaseDefault[lang];
    }
};

const stripePromise = loadStripe(STRIPE_PUB_KEY);

const BookingForm = ({
    event,
    selectEvent,
    createSetupIntentAsync,
    grantPermissionAsync,
    purposesListStore,
    setCPurposesListAsync,
}) => {
    const [percent, setPercent] = useState(60);
    const [step, setStep] = useState(1);
    const [granted, setGranted] = useState(true);
    const [cPurposes, setcPurposes] = useState();
    const [bookedErrResult, setBookedErrResult] = useState(null);
    const [autoriseCGU, setAutoriseCGU] = useState(false);
    const [autorisePPD, setAutorisePPD] = useState(false);

    useEffect(() => {
        if (event.price && event.price !== event.doctor.pricing) setStep(1);
        else setStep(2);
    }, []);

    useEffect(() => {
        setCPurposesListAsync(event.doctor._id);
    }, []);

    const handleCPurposesChange = (e) => {
        setcPurposes(e);
    };

    const handleGoBack = () => {
        navigateTo('/');
        selectEvent(null);
    };

    const handleNext = async (setupIntent) => {
        console.log('setupIntent', setupIntent);
        if (step === 4) {
            const res = await createSetupIntentAsync(event._id, {
                setupIntent,
                cPurposes,
                autoriseCGU,
                autorisePPD,
            });
            if (res !== 401) {
                notification.success({
                    message: TEXTS.sucessBookingMessage[lang],
                    description: TEXTS.sucessBookingDescription[lang],
                });
                setStep(5);
                setPercent(100);
            } else {
                setBookedErrResult(true);
            }
        } else {
            if (step === 5) {
                selectEvent(null);
                if (granted) {
                    grantPermissionAsync(event.doctor._id);
                }
                handleGoBack();
            }

            setPercent(percent + 15);
            setStep(step + 1);
        }
    };

    const showStep = () => {
        switch (step) {
            case 1:
                return (
                    <PricingWarning pricing={event.price} price={event.doctor.pricing} />
                );
            case 2:
                return (
                    <ConfirmReservation
                        date={
                            event
                                ? DateTime.fromISO(event.date)
                                : DateTime.fromMillis(Date.now())
                        }
                    />
                );
            case 3:
                return (
                    <Form layout="vertical" style={{ width: '40%' }}>
                        <Form.Item rules={[{ required: true }]}>
                            <Select onChange={handleCPurposesChange}>
                                {purposesListStore.map((opt, key) => (
                                    <Select.Option value={opt.label} key={key.toString()}>
                                        {opt.label}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Form>
                );
            case 4:
                return (
                    <div>
                        <h1>
                            Aucune somme ne sera débitée avant la fin de la consultation.
                        </h1>
                        <ul>
                            <li>
                                Le montant maximal qui peut vous etre demandé par le
                                professionnel de santé est de 25€.
                            </li>
                            <li>Le montant exact sera validé en fin de consultation</li>
                            <li>
                                Si la consultation n&apos;as pas lieu, vous n&apos;aurez rien à
                                payer.
                            </li>
                            <li>
                                Le praticien peut décider de ne pas vous faire payer la
                                consultation, par example si vous avez l&apos;habtitude de ne pas
                                avancer les frais
                            </li>
                        </ul>
                        <Elements
                            stripe={stripePromise}
                            className="booking-steps-container"
                        >
                            <CheckoutForm
                                handleNext={handleNext}
                                autoriseCGU={autoriseCGU}
                                autorisePPD={autorisePPD}
                                setAutoriseCGU={setAutoriseCGU}
                                setAutorisePPD={setAutorisePPD}
                            />
                        </Elements>
                    </div>
                );

            case 5:
                return <ImagePermission granted={granted} setGranted={setGranted} />;
            default:
                return <div />;
        }
    };

    const getStepTitle = () => {
        const { price, doctor } = event;
        switch (step) {
            case 1:
                return doctor && price < doctor.pricing
                    ? `${TEXTS.step0PriceDecrease[lang]}`
                    : `${TEXTS.step0Title[lang]}`;
            case 2:
                return event
                    ? `${TEXTS.step1Title[lang]} ${getFullName(event.doctor)}`
                    : '';
            case 3:
                return 'Veuillez indiquer le motif de votre consultation';
            case 4:
                return TEXTS.step3Title[lang];
            case 5:
                return TEXTS.step4Title[lang];
            default:
                return '';
        }
    };

    return (
        <Card
            id={step === 4 ? 'checkout-card' : ''}
            className="booking-form"
            actions={
                step !== 4
                    ? [
                        step === 1 ? (
                            <>
                                <Button
                                    onClick={handleGoBack}
                                    size="large"
                                    style={{ marginRight: 30 }}
                                >
                                    {TEXTS.returnButton[lang]}
                                </Button>
                                <Button onClick={handleNext} size="large" type="primary">
                                    {prepareButtonLabel(step)}
                                </Button>
                            </>
                        ) : (
                            <Button onClick={handleNext} size="large" type="primary">
                                {prepareButtonLabel(step)}
                            </Button>
                        ),
                    ]
                    : null
            }
        >
            {bookedErrResult ? (
                <Result
                    status="500"
                    title={TEXTS.bookedErrResultTitle[lang]}
                    subTitle={TEXTS.bookedErrResultSubTitle[lang]}
                    extra={(
                        <Button size="large" onClick={handleGoBack} type="primary">
                            {TEXTS.bookedErrResultExtra[lang]}
                        </Button>
                    )}
                />
            ) : (
                <>
                    <Progress
                        strokeColor={{
                            from: '#108ee9',
                            to: '#87d068',
                        }}
                        percent={percent}
                        status="active"
                    />
                    <div className="booking-steps-container">
                        <Title level={3} style={{ marginBottom: 30, fontSize: 18 }}>
                            {getStepTitle()}
                        </Title>
                        {showStep()}
                    </div>
                </>
            )}
        </Card>
    );
};

BookingForm.propTypes = {
    event: PropTypes.object.isRequired,
    selectEvent: PropTypes.func.isRequired,
    createSetupIntentAsync: PropTypes.func.isRequired,
    grantPermissionAsync: PropTypes.func.isRequired,
    purposesListStore: PropTypes.array.isRequired,
    setCPurposesListAsync: PropTypes.func.isRequired,
};

const stateToProps = state => ({
    event: state.consultations.selectedEvent,
    purposesListStore: state.purposes.cPurposesList,
});

const dispatchToProps = dispatch => bindActionCreators(
    {
        selectEvent,
        createSetupIntentAsync,
        grantPermissionAsync,
        setCPurposesListAsync,
    },
    dispatch,
);

export default connect(stateToProps, dispatchToProps)(BookingForm);
