export default {
    inputErrorTypeEmail: {
        fr: 'Veuillez insérer une adresse email valide.',
        en: 'Invalid e-mail address.',
    },
    inputErrorRequired: {
        fr: 'Ce champ est requis.',
        en: 'This field is required.',
    },
    loginSubmitButton: {
        fr: 'Se connecter',
        en: 'Sign in',
    },
};
