/* eslint-disable no-restricted-syntax */
/* eslint-disable no-underscore-dangle */
import {
    SET_CONSULTATIONS_LIST,
    SET_PUBLIC_CONSULTATIONS_LIST,
    SELECT_EVENT,
    REMOVE_CONSULTATION,
    SET_ACTIVE_TELEC,
    SET_STARTING_NOW,
    SET_TELEC_HISTORY,
} from '../../actions/consultations';

const initialState = {
    consultationsList: [],
    publicEvents: [],
    telecHistory: [],
    selectedEvent: null,
    activeTelec: null,
    startingNow: null,
};

const consultations = (state = initialState, action) => {
    switch (action.type) {
        case SET_CONSULTATIONS_LIST:
            return { ...state, consultationsList: action.payload };
        case SET_TELEC_HISTORY:
            return { ...state, telecHistory: action.payload };
        case SET_STARTING_NOW:
            return { ...state, startingNow: action.payload };
        case SET_ACTIVE_TELEC:
            return { ...state, activeTelec: action.payload };
        case SET_PUBLIC_CONSULTATIONS_LIST:
            return { ...state, publicEvents: action.payload };
        case SELECT_EVENT:
            return { ...state, selectedEvent: action.payload };
        case REMOVE_CONSULTATION:
            return {
                ...state,
                consultationsList: state.consultationsList.filter(
                    element => element._id !== action.payload,
                ),
            };
        default:
            return state;
    }
};

export default consultations;
