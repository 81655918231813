import {
    SET_USER, SET_EVENT, SET_DOCS, SET_REMOVE_DOC,
} from '../../actions/auth';

const initialState = {
    user: null,
    event: null,
};

const auth = (state = initialState, action) => {
    switch (action.type) {
        case SET_USER:
            return { user: action.payload };
        case SET_DOCS:
            return { ...state, user: { ...state.user, documents: action.payload } };
        case SET_REMOVE_DOC:
            return { ...state, user: { ...state.user, documents: state.user.documents.filter(doc => doc._id !== action.payload) } };
        case SET_EVENT:
            return { event: action.payload };
        default:
            return state;
    }
};

export default auth;
