/* eslint-disable no-underscore-dangle */
import {
    SELECT_CPURPOSE, SET_CPURPOSES_LIST,
} from '../../actions/purpose';

const initialState = {
    cPurposesList: [],
    selectedCPurpose: null,
};

const cPurpose = (state = initialState, action) => {
    switch (action.type) {
        case SET_CPURPOSES_LIST:
            return { ...state, cPurposesList: action.payload };
        case SELECT_CPURPOSE:
            return { ...state, selectedCPurpose: action.payload };
        default:
            return state;
    }
};

export default cPurpose;
