import commonTexts from '../../../config/common.texts';

const { inputErrorRequired } = commonTexts;

export default {
    inputErrorRequired,
    inputFirstName: {
        fr: 'Prénom',
        en: 'First name',
    },
    inputLastName: {
        fr: 'Nom',
        en: 'Last name',
    },
    fullName: {
        fr: 'Nom et Prénom',
        en: 'Full name',
    },
    daysPlaceholder: {
        fr: 'Jour',
        en: 'Day',
    },
    MonthPlaceholder: {
        fr: 'Mois       ',
        en: 'Month      ',
    },
    YearPlaceholder: {
        fr: 'Année',
        en: 'Year',
    },
    inputLabel: {
        fr: 'Date de naissance',
        en: 'Birthdate',
    },
    nextButton: {
        fr: 'Suivant',
        en: 'Next',
    },
    ageLimit: {
        fr: 'Vous devriez être né entre 1920 et 2006',
        en: 'Must be born between 1940 and 2006',
    },
};
