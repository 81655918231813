import React from 'react';
import {
    Form, Input, Button,
} from 'antd';
import PropTypes from 'prop-types';

import './styles.css';
import FORM_RULES from '../../helpers/formRules';

const SecurityNumberForm = ({ handleNext }) => (
    <Form onFinish={handleNext} className="flex-column">
        <Form.Item
            rules={[
                FORM_RULES.required,
                {
                    validator: (_, value) => (value && value.length === 15
                        ? Promise.resolve()
                        : Promise.reject('Numéro de sécurité sociale invalide')),
                },
            ]}
            name="securityNumber"
        >
            <Input
                autoFocus
                size="large"
                className="m-right-small"
                placeholder="Numéro de sécurité sociale"
            />
        </Form.Item>
        {/* <Form.Item
            name="lastName"
        >
            <Input
                size="large"
                className="m-right-small"
                placeholder="Nom du médecin traitant"
            />
        </Form.Item>
        <Form.Item
            name="firstName"
        >
            <Input
                size="large"
                className="m-right-small"
                placeholder="Prénom du médecin traitant"
            />
        </Form.Item> */}

        <Form.Item className="form-submit-button">
            <Button
                className="center-button"
                style={{ marginBottom: -24 }}
                htmlType="submit"
                size="large"
                type="primary"
            >
                Suivant
            </Button>
        </Form.Item>
    </Form>
);

SecurityNumberForm.propTypes = {
    handleNext: PropTypes.func.isRequired,
};

export default SecurityNumberForm;
