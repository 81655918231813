import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useStripe } from '@stripe/react-stripe-js';
import { getById } from '../../api/consultations';

const PaymentVerification = ({
    showStripe, telecId, onOk, onError,
}) => {
    const stripe = useStripe();

    useEffect(() => {
        const checkPaymentStatus = async () => {
            try {
                const res = await getById(telecId);
                if (res.data) {
                    if (res.data.paymentError) {
                        if (res.data.paymentError.code === 'authentication_required') {
                            const intent = res.data.paymentError.meta;
                            const { error } = await stripe.confirmCardPayment(
                                intent.client_secret,
                                {
                                    payment_method: intent.last_payment_error.payment_method.id,
                                },
                            );

                            if (error) {
                                if (error.code === 'payment_intent_authentication_failure') onError('auth_failed');
                                else {
                                    onOk();
                                }
                            } else {
                                onOk();
                            }
                        } else {
                            onError('insufficient_funds');
                        }
                    } else {
                        onOk();
                    }
                }
            } catch (err) {
                // nvm
            }
        };
        if (showStripe) {
            checkPaymentStatus();
        }
    }, [showStripe]);

    return <div />;
};

PaymentVerification.propTypes = {
    showStripe: PropTypes.bool,
    telecId: PropTypes.string.isRequired,
    onOk: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
};

PaymentVerification.defaultProps = {
    showStripe: null,
};

export default PaymentVerification;
