export default {
    descriptionPricing1stItem: {
        fr: 'Consultation Video',
        en: 'Pricing',
    },
    descriptionPricing2stItem: {
        fr: 'Consultation video de nuit (aprés 20h)',
        en: 'Pricing',
    },
    descriptionPricing3stItem: {
        fr: 'Consultation video Samedi/Dimanche',
        en: 'Pricing',
    },
    description2ndItem: {
        fr: 'Devise acceptée',
        en: 'Accepted currency',
    },
    description3rdItem: {
        fr: 'Pays',
        en: 'Country',
    },
    description4rdItem: {
        fr: 'Langues parlées',
        en: 'Spoken languages',
    },
    description5thItem: {
        fr: 'Présentation',
        en: 'Presentation',
    },
    description6thItem: {
        fr: 'Formations',
        en: 'Education',
    },
    description6thItemUniversity: {
        fr: 'Université',
        en: 'University',
    },
    description6thItemDiploma: {
        fr: 'Diplome',
        en: 'Diploma',
    },
    description6thItemYear: {
        fr: 'Année ',
        en: 'Year ',
    },
    description7thItem: {
        fr: 'Réseaux sociaux',
        en: 'Social Media',
    },
    buttonCheckProfile: {
        fr: 'Consulter son profil',
        en: 'Check full profile',
    },
};
