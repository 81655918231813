import axios from 'axios';
import { API_CONSULTATIONS, API_REQ_HEADER, API_DOCTORS } from '../config';

const getAll = async () => {
    const res = await axios.get(`${API_CONSULTATIONS}`, API_REQ_HEADER);
    return res.data;
};

const getAllByUser = async () => {
    const res = await axios.get(`${API_CONSULTATIONS}/booked`, API_REQ_HEADER);
    return res.data;
};

const getById = async (id) => {
    const res = await axios.get(`${API_CONSULTATIONS}/${id}`, API_REQ_HEADER);
    return res.data;
};

const remove = async (id) => {
    const res = await axios.delete(`${API_CONSULTATIONS}/${id}`, API_REQ_HEADER);
    return res.data;
};

const getClientSecret = async (id) => {
    const res = await axios.get(`${API_CONSULTATIONS}/${id}/setup-intent`, API_REQ_HEADER);
    return res.data;
};

const createSetupIntent = async (id, body) => {
    const res = await axios.post(`${API_CONSULTATIONS}/${id}/setup-intent`, body, API_REQ_HEADER);
    return res.data;
};


const getHistory = async () => {
    const res = await axios.get(`${API_CONSULTATIONS}/history`, API_REQ_HEADER);
    return res.data;
};

const updatePaymentStatus = async (id, body) => {
    const res = await axios.put(`${API_CONSULTATIONS}/${id}/payment-status`, body, API_REQ_HEADER);
    return res.data;
};

const getDoctorsbyName = async (query) => {
    const res = await axios.get(`${API_DOCTORS}/byname?name=${query}`, API_REQ_HEADER);
    return res.data;
};


export {
    getAll, getById, remove, getAllByUser, getClientSecret,
    getHistory, createSetupIntent, updatePaymentStatus, getDoctorsbyName,
};
