/* eslint-disable no-underscore-dangle */
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { DateTime } from 'luxon';
import { connect } from 'react-redux';
import { Image } from 'antd';
import CustomAvatar from '../../components/Avatar';
import { loadProfileImage, loadImage } from '../../api/images';
import './styles.css';

const parseDate = date => DateTime.fromJSDate(date).toFormat("dd/MM/yyyy 'à' HH':'mm ");

const SentText = ({ isUser, date }) => {
    if (isUser) {
        return (
            <div className="message--sent">
                <span>{parseDate(date)}</span>
            </div>
        );
    }
    return <span className="message--sent-date">{parseDate(date)}</span>;
};

SentText.propTypes = {
    isUser: PropTypes.bool.isRequired,
    date: PropTypes.string.isRequired,
};

const getClassNames = isUser => classnames('message', {
    'message-user-receiver': isUser,
});

const getTextClassNames = isUser => classnames({
    'message-text-contact': !isUser,
    'message-text-user': isUser,
});

const Messages = ({ messages, user }) => {
    const [imageIsOpen, setImageIsOpen] = React.useState({});

    return messages.map((message, index) => {
        const { content, time, sender } = message;
        const isUser = message.sender === user._id;
        const key = `msg-${index}`;

        const splitContent = content.split('##');
        const [tag, text] = splitContent.length > 1 ? splitContent : [null, splitContent[0]];

        let contentEl;
        switch (tag) {
            case 'img':
                contentEl = (
                    <div className={getTextClassNames(isUser)}>
                        <Image
                            preview={{ visible: false }}
                            width={200}
                            src={loadImage(
                                user._id,
                                'photos',
                                'preview',
                                text,
                            )}
                            onClick={() => setImageIsOpen({ ...imageIsOpen, [key]: true })}
                        />
                        <div style={{ display: 'none' }}>
                            <Image.PreviewGroup
                                preview={{
                                    visible: imageIsOpen[key],
                                    onVisibleChange: vis => setImageIsOpen({ ...imageIsOpen, [key]: vis }),
                                }}
                            >
                                <Image
                                    src={loadImage(
                                        user._id,
                                        'photos',
                                        'lossless',
                                        text,
                                    )}
                                />
                            </Image.PreviewGroup>
                        </div>
                    </div>
                );
                break;
            default:
                contentEl = <p className={getTextClassNames(isUser)}>{text}</p>;
                break;
        }

        return (
            <div key={key} className={getClassNames(isUser)} id={key}>
                {isUser ? (
                    <CustomAvatar user={user} />
                ) : (
                    <CustomAvatar
                        src={loadProfileImage(sender, 'thumbnail')}
                        user={user}
                    />
                )}
                <SentText isUser={isUser} date={time} />
                {contentEl}
            </div>
        );
    });
};

Messages.propTypes = {
    user: PropTypes.object.isRequired,
    messages: PropTypes.arrayOf(
        PropTypes.shape({
            content: PropTypes.string,
            time: PropTypes.string,
            sender: PropTypes.string,
        }),
    ),
};

Messages.defaultProps = {
    messages: [],
};

const stateToProps = state => ({
    user: state.auth.user,
});

export default connect(stateToProps, null)(Messages);
