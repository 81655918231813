/* eslint-disable react/destructuring-assignment */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    Tag, Table, Typography,
} from 'antd';
import { getTelecHistoryAsync } from '../../redux/actions/consultations';
import { parseDateForTelec, getFullName } from '../../helpers/functions';
import { getLang } from '../../config/helpers';
import TEXTS from './_texts/telecHistory.texts';

const lang = getLang();

const columns = [
    {
        title: TEXTS.doctor[lang],
        dataIndex: 'doctor',
        key: 'doctor',
        sorter: (a, b) => getFullName(a.doctor) > getFullName(b.doctor),
        render: doctor => (doctor ? <span>{`Dr. ${getFullName(doctor)}`}</span> : null),
    },
    {
        title: 'Date',
        dataIndex: 'date',
        key: 'date',
        sorter: (a, b) => a.date > b.date,
        render: date => <span>{parseDateForTelec(date)}</span>,
    },
    {
        title: TEXTS.cPurpose[lang],
        dataIndex: 'cPurposes',
        key: 'cPurposes',
        render: cPurposes => (
            <>
                {cPurposes.map(c => (
                    <Tag color="processing" key={c}>
                        {c.toUpperCase()}
                    </Tag>
                ))}
            </>
        ),
        responsive: ['sm'],
    },
];

const { Title } = Typography;

const TelecHistory = ({ getTelecHistoryAsync, consultationsList, user }) => {
    useEffect(() => {
        getTelecHistoryAsync();
    }, [user]);

    return (
        <div>
            <div className="flex-column">
                <Title level={3} style={{ textAlign: 'center', marginBottom: 40 }}>
                    {TEXTS.title[lang]}
                </Title>
            </div>
            <div className="section-content-container" style={{ opacity: 0.85 }}>
                <Table
                    columns={columns}
                    dataSource={consultationsList}
                    locale={{ emptyText: TEXTS.emptyList[lang] }}
                />
            </div>
        </div>
    );
};

TelecHistory.propTypes = {
    consultationsList: PropTypes.array.isRequired,
    getTelecHistoryAsync: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
};

const stateToProps = state => ({
    user: state.auth.user,
    consultationsList: state.consultations.telecHistory,
});

const dispatchToProps = dispatch => bindActionCreators(
    {
        getTelecHistoryAsync,
    },
    dispatch,
);

export default connect(stateToProps, dispatchToProps)(TelecHistory);
