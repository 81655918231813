/* eslint-disable no-underscore-dangle */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Layout, Spin } from 'antd';
import AppHeader from '../components/AppHeader';
import {
    setStartingNow,
    setActiveTelec,
    setConsultationsListAsync,
} from '../redux/actions/consultations';
import history from '../history';
import { checkAuthAsync, disconnectAsync } from '../redux/actions/auth';
import { isConfigComplete } from '../helpers/functions';
import Routes, { AuthRoutes } from '../routes';
import StartTelec from '../sections/StartTeleconsultation';
import TelecModal from '../components/TelecModal';
import notificationsListner from '../redux/actions/notifications';
import SideBar from '../components/Sidebar';
import './styles.css';
import IdleTimer from 'react-idle-timer';

const { Content } = Layout;

const resolveCurrentUrl = () => history.location.pathname.split('/')[1] || '';
const isAuthRoute = () => resolveCurrentUrl() === 'auth';

const notAuthRoute = !isAuthRoute();

class App extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            timeout: 60000 * 30, // 60000 * MINUTES
            showModal: false,
            userLoggedIn: false,
            isTimedOut: false,
            isListeningToNotifs: false,
        };

        this.idleTimer = null;
        this.onAction = this._onAction.bind(this);
        this.onActive = this._onActive.bind(this);
        this.onIdle = this._onIdle.bind(this);
    }

    componentDidMount() {
        const { checkAuthAsync, user, setActiveTelec } = this.props;
        checkAuthAsync();
        if (notAuthRoute && !user) {
            history.replace('/auth');
        }
        history.listen((location) => {
            const { activeTelec } = this.props;
            if (location.pathname !== '/start' && activeTelec) {
                setActiveTelec(null);
            }
            if (location.pathname) {
                this.setState({ oldLocation: location.pathname });
            }
        });
    }

    componentDidUpdate(props) {
        const { user, selectedEvent, activeTelec } = this.props;
        const { oldLocation } = this.state;
        const current = history.location.pathname;

        if (
            user
      && isConfigComplete(user)
      && user.email_verified
      && (current === '/config' || current === '/verif-email')
        ) {
            history.replace('/');
        }

        if (props.user !== user) {
            this.authCheck();
        }

        if (props.selectedEvent !== selectedEvent) {
            this.handleEvent();
        }

        if (props.activeTelec !== activeTelec) {
            if (activeTelec) {
                history.replace('/start');
            } else if (oldLocation !== '/ended') {
                if (oldLocation === '/my-bookings') {
                    history.push('/my-bookings');
                } else {
                    history.replace('/');
                }
            }
        }
    }

  // eslint-disable-next-line consistent-return
  handleEvent = () => {
      const { user, selectedEvent } = this.props;
      if (!user && selectedEvent) {
          return history.replace('/auth');
      }
      if (user && selectedEvent) {
          return history.replace('/booking');
      }

      if (user && !selectedEvent && history.location.pathname === '/booking') {
          return history.replace('/');
      }
  };

  authCheck = async () => {
      const { user, consultationsList, setConsultationsListAsync } = this.props;
      const { oldLocation, isListeningToNotifs } = this.state;
      let notifListner;
      if (user) {
          if (!isConfigComplete(user)) {
              history.replace('/config');
          } else if (!user.email_verified) {
              history.replace('/verif-email');
          } else {
              switch (oldLocation) {
                  case '/start':
                  case '/auth':
                  case '/':
                  case null:
                  case undefined:
                      history.replace('/');
                      break;
                  default:
                      history.replace(oldLocation);
              }
              this.handleEvent();
              this.forceUpdate();
              if (!consultationsList.length) {
                  setConsultationsListAsync();
              }
              if (!isListeningToNotifs) {
                  notifListner = await notificationsListner(
                      user._id,
                      setConsultationsListAsync,
                  );
                  this.setState({ isListeningToNotifs: true });
              }
          }
      } else {
          history.replace('/auth');
          if (notifListner) {
              notifListner();
          }
          this.forceUpdate();
      }
  };

  _onAction = (e) => {
      this.setState({ isTimedOut: false });
  };

  _onActive = (e) => {
      this.setState({ isTimedOut: false });
  };

  _onIdle = (e) => {
      const { disconnectAsync } = this.props;
      const current = history.location.pathname;
      const isTimedOut = this.state.isTimedOut;
      if (isTimedOut && current !== '/auth') {
          localStorage.setItem('disconnected', true);
          disconnectAsync();
      } else {
          this.setState({ showModal: true });
          this.idleTimer.reset();
          this.setState({ isTimedOut: true });
      }
  };

  render() {
      const {
          activeTelec,
          appIsLoading,
          consultationsList,
          telecStartingNow,
          setStartingNow,
          user,
      } = this.props;

      if (appIsLoading) {
          return (
              <div style={{ width: '100vw', height: '100vh' }}>
                  <Spin
                      spinning
                      size="large"
                      style={{ margin: '20% auto', display: 'block' }}
                  />
              </div>
          );
      }

      if (activeTelec) return <StartTelec />;

      const { oldLocation } = this.state;

      return (
          <>
              <IdleTimer
                  ref={(ref) => {
                      this.idleTimer = ref;
                  }}
                  element={document}
                  onActive={this._onAction}
                  onIdle={this._onIdle}
                  onAction={this._onActive}
                  debounce={250}
                  timeout={this.state.timeout}
              />
              <Layout>
                  {user && <SideBar currentRoute={oldLocation} />}
                  <Layout className="layout">
                      {user && <AppHeader currentRoute={oldLocation} />}
                      <Content className="app-content">
                          {user ? (
                              <>
                                  <div className="section-container">
                                      <Routes />
                                  </div>
                                  {consultationsList.map(telec => (
                                      <TelecModal
                                          key={telec._id}
                                          pickDevice
                                          open={telecStartingNow !== null}
                                          telec={telecStartingNow || telec}
                                          handleClose={() => {
                                              setStartingNow(null);
                                          }}
                                      />
                                  ))}
                              </>
                          ) : (
                              <AuthRoutes />
                          )}
                      </Content>
                  </Layout>
              </Layout>
          </>
      );
  }
}

App.propTypes = {
    checkAuthAsync: PropTypes.func.isRequired,
    user: PropTypes.object,
    selectedEvent: PropTypes.object,
    appIsLoading: PropTypes.bool.isRequired,
    setStartingNow: PropTypes.func.isRequired,
    activeTelec: PropTypes.object,
    telecStartingNow: PropTypes.object,
    setActiveTelec: PropTypes.func.isRequired,
    setConsultationsListAsync: PropTypes.func.isRequired,
    consultationsList: PropTypes.array.isRequired,
};

App.defaultProps = {
    user: null,
    selectedEvent: null,
    activeTelec: null,
    telecStartingNow: null,
};

const stateToProps = state => ({
    user: state.auth.user,
    selectedEvent: state.consultations.selectedEvent,
    appIsLoading: state.loading.appIsLoading,
    activeTelec: state.consultations.activeTelec,
    telecStartingNow: state.consultations.startingNow,
    consultationsList: state.consultations.consultationsList,
});

const dispatchToProps = dispatch => bindActionCreators(
    {
        checkAuthAsync,
        setStartingNow,
        setActiveTelec,
        setConsultationsListAsync,
        disconnectAsync,
    },
    dispatch,
);

export default connect(stateToProps, dispatchToProps)(App);
