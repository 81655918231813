export default {
    optionCountry: {
        fr: ' Pays',
        en: ' Country',
    },
    inputPassword: {
        fr: 'Mot de passe',
        en: 'Password',
    },
    inputPasswordRequiredError: {
        fr: 'Veuillez insérer votre mot de passe.',
        en: 'This field is required.',
    },
    inputPasswordMinLength: {
        fr: 'Votre mot de passe doit contenir au moins 6 caractères.',
        en: 'Password must be at least 6 characters long.',
    },
    confirmPassword: {
        fr: 'Confirmez votre mot de passe',
        en: 'Confirm your password please',
    },
    confirmPasswordMatchError: {
        fr: 'Mot de passes incompatibles.',
        en: "Passwords don't match.",
    },
    inputPhoneNumber: {
        fr: 'Numéro de téléphone',
        en: 'Phone number',
    },
    inputPhoneNumberRequiredError: {
        fr: 'Veuillez insérer votre numéro de téléphone.',
        en: 'This field is required.',
    },
    checkboxAgreementText: {
        fr: 'Vous devez accepter les ',
        en: 'I accept the',
    },
    checkboxAgreementButton: {
        fr: "conditions d'utilisation",
        en: 'Terms of service and privacy policy',
    },
    checkboxAgreementRequiredError: {
        fr: "Vous devriez accepter les conditions d'utilisation.",
        en: 'You must accept our terms of service and the privacy policy.',
    },
    buttonSubmit: {
        fr: 'Créer mon compte',
        en: 'Register',
    },
    notificationMessage: {
        fr: 'Email envoyé',
        en: 'Email sent',
    },
    notificationDescription: {
        fr: "Un email de confirmation vous a été envoyé, veuillez le consulter afin d'activer votre compte.",
        en: 'A confirmation email has been sent to you successfully, please check your e-mail inbox and follow the instructions in order to activate your account.',
    },


};
