import React from 'react';
import { Card, Tabs } from 'antd';
import { IdcardFilled } from '@ant-design/icons';
import StetoscopeIcon from './stethoscope-solid';
import './styles.css';
import IdentityTab from './IdentityTab';
import HealthTab from './HealthTab';


const { TabPane } = Tabs;

const Profile = () => (
    <div className="profile-section">
        <Tabs className="full-width" style={{ fontSize: 24 }}>
            <TabPane
                tab={(
                    <div className="profile-tab">
                        <IdcardFilled />
                        Mon profil
                    </div>
                )}
                key="identity"
            >
                <Card><IdentityTab /></Card>
            </TabPane>
            <TabPane
                tab={(
                    <div className="profile-tab">
                        <StetoscopeIcon className="stetoB" />
                        Médecin traitant
                    </div>
                )}
                key="health"
            >

                <Card>
                    <HealthTab />
                </Card>
            </TabPane>
        </Tabs>
    </div>
);

Profile.propTypes = {};

export default Profile;
