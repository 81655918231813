import React from 'react';
import {
    Router,
    Switch,
    Route,
} from 'react-router-dom';
import history from './history';
import DoctorsList from './sections/DoctorsList';
import Auth from './sections/Auth';
import InitialConfig from './sections/InitialConfig';
import EmailVerification from './sections/EmailVerification';
import BookingForm from './sections/BookingForm';
import MyBookings from './sections/MyBookings';
import PostTelec from './sections/PostTelec';
import Profile from './sections/Profile';
import ImageSection from './sections/ImagesSection';
import Documents from './sections/Documents';


export default function Routes() {
    return (
        <Router history={history}>
            <Switch>
                <Route exact path="/" component={DoctorsList} />
                <Route path="/auth" component={Auth} />
                <Route path="/config" component={InitialConfig} />
                <Route path="/booking" component={BookingForm} />
                <Route path="/profile" component={Profile} />
                <Route exact path="/consultations" component={MyBookings} />
                <Route path="/images" component={ImageSection} />
                <Route path="/documents" component={Documents} />
                <Route path="/ended" component={PostTelec} />
                <Route path="/verif-email" component={EmailVerification} />
            </Switch>
        </Router>
    );
}

export function AuthRoutes() {
    return (
        <Router history={history}>
            <Switch>
                <Route path="/auth" component={Auth} />
            </Switch>
        </Router>
    );
}
