export default {
    uploadButton: {
        fr: 'Ajouter un document',
        en: 'Upload a document',
    },
    deleteButton: {
        fr: 'Supprimer ce document',
        en: 'Delete this document',
    },
    uploadSuccessMSG: {
        fr: 'Document ajouté avec succès.',
        en: 'Document uploaded successfully.',
    },
    uploadErrorMSG: {
        fr: "Une erreur s'est produite.",
        en: 'An error has occured, please retry.',
    },
    deleteMessage: {
        fr: 'Document supprimé avec succès',
        en: 'Document deleted successfully',
    },
};
