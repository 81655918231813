import commonTexts from '../../../config/common.texts';

const { inputErrorTypeEmail, inputErrorRequired } = commonTexts;

export default {
    modalTitle: {
        fr: 'Récuperer mon mot de passe',
        en: 'Recover my password',
    },
    buttonCancel: {
        fr: 'Annuler',
        en: 'Cancel',
    },
    buttonSubmit: {
        fr: 'Envoyer un e-mail de récupération',
        en: 'Send a password recovery email',
    },
    inputErrorTypeEmail,
    inputErrorRequired,
};
