export default {
    acceptedCards: {
        fr: 'Cartes accéptés',
        en: 'Accepted cards',
    },
    input1stItem: {
        fr: 'Nom du détenteur de la carte',
        en: 'Name of the card owner',
    },
    input2ndItem: {
        fr: 'Numéro de la carte',
        en: 'Card number',
    },
    submitButton: {
        fr: 'Valider',
        en: 'Validate',
    },
    input1stItemError: {
        fr: 'Veuillez indiquer le nom du détenteur de la carte.',
        en: 'Please indicate the name of the card owner.',
    },
    autoriseItemError: {
        fr: 'Veuillez accepter les réglement de AeroHealth.',
        en: 'Please accept MyélineSanté regulations.',
    },
};
