import React from 'react';
import {
    Form, Input, Typography, Button,
} from 'antd';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import { getLang } from '../../config/helpers';
import TEXTS from './_texts/IdentityForm.texts';
import './styles.css';
import FORM_RULES from '../../helpers/formRules';
import CustomDatePicker from '../../components/CustomDatePicker';

const lang = getLang();
const { Title } = Typography;

const dayMonthFormatter = a => (a < 10 ? `0${a}` : a);

const IdentityForm = ({ handleNext }) => {
    const [form] = Form.useForm();
    const onFinish = (values) => {
        const birthDate = DateTime.fromISO(
            `${values.year}-${dayMonthFormatter(values.month)}-${dayMonthFormatter(
                values.day,
            )}`,
        );
        handleNext({
            firstName: values.firstName,
            lastName: values.lastName,
            birthDate,
        });
    };
    return (
        <Form
            form={form}
            id="birthdate-form"
            onFinish={onFinish}
            className="flex-column"
        >
            <Title level={4} style={{ marginBottom: 15, fontSize: 14 }}>
                {TEXTS.fullName[lang]}
            </Title>
            <Form.Item rules={[FORM_RULES.required]} name="lastName">
                <Input
                    size="large"
                    style={{ marginLeft: 5 }}
                    placeholder={TEXTS.inputLastName[lang]}
                />
            </Form.Item>
            <Form.Item rules={[FORM_RULES.required]} name="firstName">
                <Input
                    autoFocus
                    size="large"
                    style={{ marginLeft: 5 }}
                    placeholder={TEXTS.inputFirstName[lang]}
                />
            </Form.Item>
            <Title level={4} style={{ marginBottom: 15, fontSize: 14 }}>
                {TEXTS.inputLabel[lang]}
            </Title>
            <CustomDatePicker form={form} />

            <Form.Item className="form-submit-button">
                <Button
                    className="center-button"
                    style={{ marginBottom: -24 }}
                    htmlType="submit"
                    size="large"
                    type="primary"
                >
                    {TEXTS.nextButton[lang]}
                </Button>
            </Form.Item>
        </Form>
    );
};

IdentityForm.propTypes = {
    handleNext: PropTypes.func.isRequired,
};

export default IdentityForm;
