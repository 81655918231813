import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    Button, Card, Progress, Typography, Result, Spin,
} from 'antd';
import { SmileOutlined } from '@ant-design/icons';
import PickGender from './PickGender';
import IdentityForm from './IdentityForm';
import { getLang } from '../../config/helpers';
import { updateUserAsync } from '../../redux/actions/auth';
import TEXTS from './_texts/index.texts';
import './styles.css';
import SecurityNumberForm from './SecurityNumber';

const lang = getLang();
const { Title } = Typography;

const InitialConfig = ({
    user, event, updateUserAsync, contentIsLoading,
}) => {
    const getPercentValue = () => {
        let percent = 25;
        if (user) {
            if (event) {
                if (user.gender) percent += 15;
                return percent;
            }
            percent = 40;
            if (user.gender) percent += 30;
        }
        return percent;
    };

    const [percent, setPercent] = useState(getPercentValue());
    const [step, setStep] = useState(1);
    const [userInfo, setUserInfo] = useState(null);
    const [userSecurityNumber, setSecurityNumber] = useState(null);
    const prepareStep = () => {
        if (!user.gender) return setStep(1);
        if (!user.firstName || !user.lastName || !user.birthDate) return setStep(2);
        if (!user.securityNumber) return setStep(3);
        return setStep(4);
    };

    const [gender] = useState('');

    useEffect(() => {
        if (user) {
            setPercent(getPercentValue);
            prepareStep();
        }
    }, [user]);

    useEffect(() => {
        if (userInfo) {
            setStep(3);
            setPercent(85);
        }
    }, [userInfo]);

    useEffect(() => {
        if (userSecurityNumber) {
            setStep(4);
            setPercent(100);
        }
    }, [userSecurityNumber]);

    const handleSecurityNumberForm = (values) => {
        const { securityNumber, firstName, lastName } = values;
        setSecurityNumber(securityNumber);
        updateUserAsync({ ...userInfo, securityNumber, doctor: { firstName, lastName } || null });
    };

    const showStep = () => {
        switch (step) {
            case 1:
                return (
                    <PickGender
                        gender={gender}
                        setGender={(gender) => {
                            updateUserAsync({ gender });
                        }}
                    />
                );
            case 2:
                return <IdentityForm handleNext={setUserInfo} />;
            case 3:
                return <SecurityNumberForm handleNext={handleSecurityNumberForm} />;
            case 4:
                return (
                    <Result
                        icon={<SmileOutlined />}
                        style={{ paddingBottom: 12 }}
                        title={TEXTS.resultTitle[lang]}
                        status="success"
                        subTitle={TEXTS.resultSubTitle[lang]}
                        extra={(
                            <Button
                                size="large"
                                onClick={() => {
                                    updateUserAsync({
                                        ...userInfo,
                                        securityNumber: userSecurityNumber,
                                    });
                                }}
                                type="primary"
                            >
                                {TEXTS.planifyButton[lang]}
                            </Button>
                        )}
                    />
                );
            default:
                return <div />;
        }
    };

    const getStepTitle = () => {
        switch (step) {
            case 1:
                return TEXTS.step1Title[lang];
            case 2:
                return TEXTS.step2Title[lang];
            case 3:
                return 'Numéro de sécurité sociale  et médecin traitant';
            default:
                return '';
        }
    };

    return (
        <Card id="initial-config" className="card-container">
            <Spin spinning={contentIsLoading} size="large">
                <Progress
                    strokeColor={{
                        from: '#108ee9',
                        to: '#87d068',
                    }}
                    percent={percent}
                    status="active"
                />
                <div className="form-child-container ">
                    <Title
                        level={step === 4 ? 3 : 4}
                        style={{ marginBottom: 30, fontSize: 18 }}
                    >
                        {getStepTitle()}
                    </Title>
                    {showStep()}
                </div>
            </Spin>
        </Card>
    );
};

InitialConfig.propTypes = {
    user: PropTypes.object,
    event: PropTypes.object,
    updateUserAsync: PropTypes.func.isRequired,
    contentIsLoading: PropTypes.bool.isRequired,
};

InitialConfig.defaultProps = {
    event: null,
    user: null,
};

const stateToProps = state => ({
    user: state.auth.user,
    event: state.consultations.selectedEvent,
    contentIsLoading: state.loading.contentIsLoading,
});

const dispatchToProps = dispatch => bindActionCreators(
    {
        updateUserAsync,
    },
    dispatch,
);

export default connect(stateToProps, dispatchToProps)(InitialConfig);
