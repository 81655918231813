import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Card } from 'antd';
import { ManOutlined, WomanOutlined, KeyOutlined } from '@ant-design/icons';
import './styles.css';
import { getLang } from '../../config/helpers';
import TEXTS from './_texts/PickGender.texts';

const lang = getLang();
const { Meta } = Card;

const PickGender = ({ gender, setGender }) => {
    const prepareClassName = (classes, isSelected) => classnames(classes.join(' '), {
        'profile-selected-gender': isSelected,
    });

    return (
        <div className="profile-gender-container">
            <Card
                hoverable
                onClick={() => setGender('male')}
                className={prepareClassName(
                    ['profile-gender-card'],
                    gender === 'male',
                )}
                cover={(
                    <ManOutlined
                        className={prepareClassName(
                            ['profile-gender-icon'],
                            gender === 'male',
                            false,
                        )}
                    />
                )}
            >
                <Meta
                    title={TEXTS.maleCard[lang]}
                    className={prepareClassName([], gender === 'male', false)}
                />
            </Card>
            <Card
                onClick={() => setGender('female')}
                hoverable
                className={prepareClassName(
                    ['profile-gender-card'],
                    gender === 'female',
                )}
                cover={(
                    <WomanOutlined
                        className={prepareClassName(
                            ['profile-gender-icon'],
                            gender === 'female',
                            false,
                        )}
                    />
                )}
            >
                <Meta
                    title={TEXTS.FemaleCard[lang]}
                    className={prepareClassName([], gender === 'female', false)}
                />
            </Card>
            <Card
                onClick={() => setGender('custom')}
                hoverable
                className={prepareClassName(
                    ['profile-gender-card'],
                    gender === 'custom',
                )}
                cover={(
                    <KeyOutlined
                        className={prepareClassName(
                            ['profile-gender-icon'],
                            gender === 'custom',
                            false,
                        )}
                    />
                )}
            >
                <Meta
                    title={TEXTS.CustomCard[lang]}
                    className={prepareClassName([], gender === 'custom', false)}
                />
            </Card>
        </div>
    );
};

PickGender.propTypes = {
    gender: PropTypes.oneOf(['male', 'female', 'custom', '']).isRequired,
    setGender: PropTypes.func.isRequired,
};

export default PickGender;
