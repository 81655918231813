/* eslint-disable array-callback-return */
import countries from '../config/countries';


const getDialingCodes = () => {
    const result = {};
    Object.keys(countries).map((countryName) => {
        const country = countries[countryName];
        result[countryName] = {
            flag: country.flag,
            name: countryName,
        };
        if (country.idd.suffixes.length === 1) {
            result[countryName].code = `${country.idd.root}${country.idd.suffixes[0]}`;
        } else {
            result[countryName].code = `${country.idd.root}`;
        }
    });
    return result;
};

export default getDialingCodes;
