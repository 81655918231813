/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
    List, Card, Button, Typography, Popconfirm,
} from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getAuthorizedDoctors } from '../../api/auth';
import { revokePermissionAsync } from '../../redux/actions/auth';
import { loadProfileImage } from '../../api/images';
import Avatar from '../../components/Avatar';
import { getFullName } from '../../helpers/functions';
import { getLang } from '../../config/helpers';
import TEXTS from './_texts/authorizedDoctorsList.texts';

const lang = getLang();
const { Title } = Typography;

const AuthorizedDoctorsList = ({ user, revokePermissionAsync }) => {
    const [accessList, setAccessList] = useState([]);

    useEffect(() => {
        if (user) {
            (async () => {
                try {
                    const res = await getAuthorizedDoctors(user.permissions);
                    setAccessList(res.data);
                } catch (err) {
                    // nada
                }
            })();
        }
    }, [user]);

    return (
        <Card className="authorized-doctors-card">
            <List
                size="large"
                header={<Title level={4}>{TEXTS.header[lang]}</Title>}
                itemLayout="horizontal"
                dataSource={accessList}
                className="authorized-doctors-list"
                renderItem={doctor => (
                    <List.Item
                        actions={[
                            <Popconfirm
                                title={TEXTS.revokePopConfirmTitle[lang]}
                                onConfirm={() => revokePermissionAsync(doctor._id)}
                                okText={TEXTS.revokePopConfirmYes[lang]}
                                cancelText={TEXTS.revokePopConfirmNo[lang]}
                            >
                                <Button type="link">{TEXTS.revoke[lang]}</Button>
                            </Popconfirm>,
                        ]}
                    >
                        <List.Item.Meta
                            style={{ alignItems: 'center' }}
                            avatar={
                                <Avatar src={loadProfileImage(doctor._id, 'thumbnail')} />
                            }
                            title={getFullName(doctor)}
                            description={doctor.speciality}
                        />
                    </List.Item>
                )}
            />
        </Card>
    );
};

AuthorizedDoctorsList.propTypes = {
    user: PropTypes.object.isRequired,
    revokePermissionAsync: PropTypes.func.isRequired,
};

const stateToProps = state => ({
    user: state.auth.user,
});

const dispatchToProps = dispatch => bindActionCreators(
    {
        revokePermissionAsync,
    },
    dispatch,
);

export default connect(stateToProps, dispatchToProps)(AuthorizedDoctorsList);
