import * as firebase from 'firebase';
import 'firebase/auth';
import 'firebase/database';

const firebaseConfig = {
    apiKey: 'AIzaSyAvjlggz16SHw9P76KrMzaqV33hBL9R87Q',
    authDomain: 'msante-a4e29.firebaseapp.com',
    projectId: 'msante-a4e29',
    storageBucket: 'msante-a4e29.appspot.com',
    messagingSenderId: '127497795999',
    appId: '1:127497795999:web:b8c8acb808f8fe850f65aa',
};


const Firebase = firebase.initializeApp(firebaseConfig);
const db = Firebase.firestore();
const auth = Firebase.auth();

auth.setPersistence(firebase.auth.Auth.Persistence.NONE);

export { db, auth };


export default Firebase;
