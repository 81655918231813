import React from 'react';
import PropTypes from 'prop-types';
import { Button, Typography } from 'antd';
import { DateTime as dt } from 'luxon';
import moment from 'moment';
import { getLang } from '../../config/helpers';
import './styles.css';

const { Title } = Typography;
const lang = getLang();

const disabledGoBack = current => current && current < moment().endOf('day');

const Header = ({ dates, daysHandler }) => (
    <div className="week">
        <div className="week-header hidden-sm show-lg">
            <Button
                onClick={() => daysHandler(-4)}
                className="btn"
                type="primary"
                size="large"
                ghost
                disabled={disabledGoBack(dates[0])}
            >
                {'<'}
            </Button>
            {dates.map((e, i) => (
                <div className="row day" key={i.toString()}>
                    <div className="col-12 header-day day-row">
                        <Title level={4} style={{ fontSize: 12, textAlign: 'center' }}>
                            {dt
                                .fromJSDate(e)
                                .setLocale(lang)
                                .toFormat('EEEE')
                                .toUpperCase()}
                        </Title>
                        <Title level={4} style={{ fontSize: 12, textAlign: 'center' }}>
                            {dt
                                .fromJSDate(e)
                                .setLocale(lang)
                                .toLocaleString({ day: 'numeric', month: 'long' })
                                .toUpperCase()}
                        </Title>
                    </div>
                </div>
            ))}
            <Button
                onClick={() => daysHandler(4)}
                className="btn"
                type="primary"
                ghost
                size="large"
            >
                {'>'}
            </Button>
        </div>
        <div
            className="calendar-responsive-header"
        >
            <Button
                onClick={() => daysHandler(-4)}
                className="btn"
                type="primary"
                size="large"
                ghost
                disabled={disabledGoBack(dates[0])}
            >
                {'<'}
            </Button>
            <div />
            <Button
                onClick={() => daysHandler(4)}
                className="btn"
                type="primary"
                ghost
                size="large"
            >
                {'>'}
            </Button>
        </div>

    </div>
);

Header.propTypes = {
    dates: PropTypes.array.isRequired,
    daysHandler: PropTypes.func.isRequired,
};

export default Header;
