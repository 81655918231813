export default {
    header: {
        fr: 'Médecins autorisés a consulter vos photos',
        en: 'Doctors allowed to consult your pictures',
    },
    revoke: {
        fr: "Supprimer l'accés",
        en: 'Revoke permission',
    },
    revokePopConfirmTitle: {
        fr: "êtes vous sûre de bien vouloir supprimer l'accés a ce médecin ?",
        en: 'Are you sure revoke permission from this doctor?',
    },
    revokePopConfirmYes: {
        fr: 'Supprimer',
        en: 'Confirm',
    },
    revokePopConfirmNo: {
        fr: 'Annuler',
        en: 'Cancel',
    },
};
