export default {
    uploadButton: {
        fr: 'Ajouter une photo',
        en: 'Upload a picture',
    },
    deleteButton: {
        fr: 'Supprimer cette photo',
        en: 'Delete this picture',
    },
    uploadSuccessMSG: {
        fr: 'Photo ajoutée avec succès.',
        en: 'Picture uploaded successfully.',
    },
    uploadErrorMSG: {
        fr: "Une erreur s'est produite.",
        en: 'An error has occured, please retry.',
    },
    cropTitle: {
        fr: 'Recadrer la photo',
        en: 'Edit Picture',
    },
    cropCancel: {
        fr: 'Annuler',
        en: 'Cancel',
    },
    deleteMessage: {
        fr: 'Photo supprimé avec succès',
        en: 'Picture deleted successfully',
    },
};
