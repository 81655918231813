import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Upload, Button, message } from 'antd';
import ImgCrop from 'antd-img-crop';
import CustomAvatar from '../Avatar';
import { addImages, loadProfileImage } from '../../api/images';
import { getBase64 } from '../../helpers/functions';
import { updateUserAsync } from '../../redux/actions/auth';
import './styles.css';

const ProfilePicture = ({
    user, onUpload, updateUserAsync,
}) => {
    const [filesList, setFilesList] = useState([]);
    const [previewImage, setPreviewImage] = useState(
        loadProfileImage(user._id, 'preview'),
    );

    const uploadImage = async (file) => {
        try {
            if (!file.url && !file.preview) {
                file.preview = await getBase64(file.originFileObj);
            }
            setPreviewImage(file.url || file.preview);
            await addImages(user._id, 'profile', file.originFileObj);
            updateUserAsync({});
            onUpload();
        } catch (err) {
            message.error('Une erreur est survenue.');
        }
    };

    return (
        <div className="flex-column" style={{ alignItems: 'center' }}>
            <CustomAvatar size={160} src={previewImage} />

            <ImgCrop
                rotate
                modalTitle="Recadrer l'image"
                modalCancel="Annuler"
            >
                <Upload
                    showUploadList={false}
                    listType="text"
                    fileList={filesList}
                    onChange={({ file }) => {
                        setFilesList([file]);
                        uploadImage(file);
                    }}
                >
                    <Button style={{ marginTop: 10 }}>
                        Changer la photo de profil
                    </Button>
                </Upload>
            </ImgCrop>
        </div>
    );
};

ProfilePicture.propTypes = {
    user: PropTypes.object.isRequired,
    updateUserAsync: PropTypes.func.isRequired,
    onUpload: PropTypes.func,
};

ProfilePicture.defaultProps = {
    onUpload: () => {},
};

const stateToProps = state => ({
    user: state.auth.user,
});

const dispatchToProps = dispatch => bindActionCreators(
    {
        updateUserAsync,
    },
    dispatch,
);

export default connect(stateToProps, dispatchToProps)(ProfilePicture);
