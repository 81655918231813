export default {
    interlucatorOffline: {
        fr: "Votre interlocuteur n'est pas connecté, merci de patienter",
        en: 'Your interlocutor is offline...',
    },
    messagePlacholder: {
        fr: 'Tapez votre message',
        en: 'Type your message here',
    },
    sendMessage: {
        fr: 'Envoyer',
        en: 'Send',
    },
    resultTitle: {
        fr: "Vous n'êtes pas autorisé a continuer",
        en: 'You are not allowed to continue',
    },
    resultSubtitle: {
        fr:
      "Veuillez utiliser votre ordinateur ou télécharger l'application mobile pour effectuer votre téléconsultation.",
        en:
      'You have to use the mobile application your computer in order to start your teleconsultation.',
    },
    backButton: {
        fr: 'Retour',
        en: 'Go back',
    },
    error_payment_message: {
        fr: 'Cette téléconsultation est désormais annulée.',
        en: 'This teleconsultation has been cancelled.',
    },
    auth_failed: {
        fr:
      'Nous ne parvenons pas à authentifier votre méthode de paiement, votre téléconsultation est annulée.',
        en:
      'We are unable to authenticate your payment method, your teleconsultation is now cancelled.',
    },
    insufficient_funds: {
        fr: 'Votre carte ne dispose pas de fonds suffisants, votre téléconsultation est annulée.',
        en: 'Your card has insufficient funds, your teleconsultation is now cancelled.',
    },
    uploadSuccessMSG: {
        fr: 'Photo ajoutée avec succès.',
        en: 'Picture uploaded successfully.',
    },
    uploadErrorMSG: {
        fr: "Une erreur s'est produite.",
        en: 'An error has occured, please retry.',
    },
};
