/* eslint-disable no-param-reassign */
import { DateTime } from 'luxon';
import { getLang } from '../config/helpers';

const lang = getLang();

// eslint-disable-next-line max-len
export const isConfigComplete = ({
    firstName, lastName, gender, birthDate, securityNumber,
}) => firstName && lastName && gender && birthDate && securityNumber;

export const getFullName = user => `${user.lastName} ${user.firstName}`;
export const getNameInitials = user => `${user.lastName[0]} ${user.firstName[0]}`;
export const getFullNameInversed = user => `${user.firstName} ${user.lastName}`;

export const parseDateForTelec = date => DateTime.fromISO(date)
    .setLocale(lang)
    .toLocaleString(DateTime.DATETIME_FULL);


// output will be always "string.length < max" + "\n" + ...rest
export const parseTextForPDF = (text, max) => {
    if (text == null) return '';
    if (text.length <= max) return text;
    const nextNewLine = /\n/.exec(text);

    const lineLength = nextNewLine ? nextNewLine.index : text.length;
    if (lineLength <= max) {
        const line = text.substr(0, lineLength);
        const rest = text.substr(lineLength + 1);
        return `${line}\n${parseTextForPDF(rest, max)}`;
    }
    let line = text.substr(0, max);
    let rest = text.substr(max);

    const res = (/([\s])[^\s]*$/.exec(line));
    if (res) { //
        line = text.substr(0, res.index);
        rest = text.substr(res.index + 1);
    } else {
        line += '-';
    }
    return `${line}\n${parseTextForPDF(rest, max)}`;
};


// Got this function from ant.design example on upload image
export function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}
