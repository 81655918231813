export default {
    paragraph: {
        fr: 'Autorisez le médecin à consulter vos documents ?',
        en: 'authorize the doctor to consult your documents ?',
    },
    yes: {
        fr: 'Oui',
        en: 'Yes',
    },
    no: {
        fr: 'Non',
        en: 'No',
    },
};
