export default {

    prepareButtonLabelCaseDefault: {
        fr: 'Suivant',
        en: 'Next',
    },
    step1Title: {
        fr: 'Veuillez indiquer votre sexe',
        en: 'What is your gender?',
    },
    step2Title: {
        fr: 'Veuillez indiquer votre',
        en: 'Please provide your : ',
    },
    resultTitle: {
        fr: 'Félicitations! Votre compte Aestheclic a été créé avec succès',
        en: 'Congratulations! you have successfully created your account',
    },
    resultSubTitle: {
        fr: 'Vous pouvez désormais programmer votre première téléconsultation de médecine ou de chirurgie esthétique',
        en: 'You can now schedule your first teleconsultation',
    },
    planifyButton: {
        fr: 'Planifier une téléconsultation',
        en: 'Shedule my first teleconsultation',
    },

};
