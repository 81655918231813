// NOTE THIS IS TEMPORARY & IT WILL BE REMOVED
export default {
    emptyEvents: {
        fr: "Ce médecin n'as aucun créneau libre pour l'instant.",
        en: 'This doctor does not have any available time slot for the moment.',
    },
    emptyDayResp: {
        fr: 'Aucun créneau disponible',
        en: 'No time slots available',
    },
};
