/* eslint-disable react/no-unescaped-entities */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, Typography } from 'antd';
import { pdfjs, Document, Page } from 'react-pdf';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
// import pdf from './msante-cgu.pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const { Paragraph } = Typography;

const CGU = ({ open, handleClose }) => {
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);


    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    return (
        <Modal visible={open} onCancel={handleClose} footer={null} width={700}>
            <Document
                file="cgu.pdf"
                onLoadSuccess={onDocumentLoadSuccess}
            >
                <Page pageNumber={pageNumber} />
            </Document>

            <div className="flex" style={{ alignItems: 'baseline' }}>
                <Paragraph className="m-right-medium">
                    {`Page ${pageNumber} de ${numPages}`}
                </Paragraph>
                <div>
                    <Button type="link" ghost onClick={() => setPageNumber(pageNumber - 1)} className="m-right-small">
                        <LeftOutlined className="m-left-small" />
                        Précédent
                    </Button>
                    <Button type="link" ghost onClick={() => setPageNumber(pageNumber + 1)}>
                        Suivant
                        <RightOutlined className="m-right-small" />
                    </Button>
                </div>

            </div>

        </Modal>
    );
};

CGU.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
};

export default CGU;
