import axios from 'axios';
import { API_PHOTOS, API_REQ_HEADER } from '../config';

const PROFILE_PICTURE_NAME = 'profile-picture.webp';

const addImages = async (userId, category, images) => {
    const data = new FormData();
    data.append('files', images);
    const res = await axios.post(
        `${API_PHOTOS}/upload/${userId}/${category}`,
        data,
        API_REQ_HEADER,
    );
    return res.data;
};

const deleteImage = async (userId, filename) => {
    const res = await axios.delete(
        `${API_PHOTOS}/remove/${userId}/${filename}`,
        API_REQ_HEADER,
    );
    return res.data;
};

const loadImage = (userId, category, resolution, filename) => (
    `${API_PHOTOS}/load/${userId}/${category}/${resolution}/${filename}?${Date.now()}`
);

const loadProfileImage = (userId, resolution) => (
    `${API_PHOTOS}/load/${userId}/profile/${resolution}/${PROFILE_PICTURE_NAME}?${Date.now()}`
);


export {
    addImages, loadImage, loadProfileImage, deleteImage,
};
