import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Switch, Tooltip } from 'antd';
import { VideoCameraOutlined, AudioOutlined, PoweroffOutlined } from '@ant-design/icons';
import { getLang } from '../../config/helpers';
import TEXTS from './_texts/mediaControls.texts';
import './styles.css';

const lang = getLang();

const MediaControls = ({ handleCheckBox, canHangup, onHangup }) => {
    const [disableCam, setDisableCam] = useState(false);
    const [disableMic, setDisableMic] = useState(false);

    const onChange = (item) => {
        handleCheckBox(item);
        if (item === 'cam') {
            setDisableCam(!disableCam);
        } else {
            setDisableMic(!disableMic);
        }
    };

    return (
        <div className="flex">
            <div className="vid-preview-item">
                <VideoCameraOutlined style={{ marginRight: 8, fontSize: 18 }} />
                <Switch
                    onChange={() => onChange('cam')}
                    checked={!disableCam}
                    defaultChecked
                />
            </div>

            <div className="vid-preview-item">
                <AudioOutlined style={{ marginRight: 8, fontSize: 18 }} />
                <Switch
                    onChange={() => onChange('mic')}
                    defaultChecked
                    checked={!disableMic}
                />
            </div>

            {canHangup ? (
                <div className="vid-preview-item" style={{ cursor: 'pointer' }}>
                    <Tooltip title={TEXTS.hangupButton[lang]}>
                        <PoweroffOutlined style={{ color: '#ff4d4f', fontSize: 20, marginTop: 2 }} onClick={onHangup} />
                    </Tooltip>
                    ,
                </div>
            ) : null}
        </div>
    );
};


MediaControls.propTypes = {
    handleCheckBox: PropTypes.func.isRequired,
    canHangup: PropTypes.bool.isRequired,
    onHangup: PropTypes.func,
};

MediaControls.defaultProps = {
    onHangup: () => {},
};

export default MediaControls;
