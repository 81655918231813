/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect, useRef } from 'react';
import { Button, message } from 'antd';
import ImageGallery from 'react-image-gallery';
import { RedoOutlined, UndoOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { deleteImageAsync } from '../../redux/actions/auth';
import { loadImage } from '../../api/images';
import Upload from './upload';
import 'react-image-gallery/styles/css/image-gallery.css';
import './styles.css';
import AuthorizedDoctorsList from './authorizedDoctorsList';
import TEXTS from './_texts/upload.texts';
import { getLang } from '../../config/helpers';

// TODO Refactor image categories
const parseImage = (filename, userId) => ({
    original: loadImage(userId, 'photos', 'lossless', filename),
    thumbnail: loadImage(userId, 'photos', 'thumbnail', filename),
});

const lang = getLang();

const ImagesSection = ({ user, deleteImageAsync }) => {
    const [parsedImages, setParsedImages] = useState([]);
    const [rotationDeg, setRotationDeg] = useState(0);
    const imageGalleryRef = useRef();

    useEffect(() => {
        if (user) {
            const imgs = [];
            user.photos.map(img => imgs.push(parseImage(img, user._id)));
            setParsedImages(imgs);
        }
    }, [user]);

    const onDeleteImage = () => {
        if (imageGalleryRef) {
            const { currentIndex } = imageGalleryRef.current.state;
            deleteImageAsync(user._id, user.photos[currentIndex]);
            imageGalleryRef.current.slideToIndex(0);
            message.success(TEXTS.deleteMessage[lang]);
        }
    };

    const openImage = (e) => {
        const imageWindow = window.open('', '_blank');
        imageWindow.document.write(`
      <html>
        <head>
        </head>
        <body>
          <img src="${e.target.src}" alt="Example">
        </body>
      </html>
      `);
        return imageWindow;
    };


    return (
        <div className="flex images-container">
            <div style={{ marginTop: 50, marginRight: 24, marginLeft: 40 }}>
                {parsedImages.length ? (
                    <div
                        className="flex"
                        style={{
                            justifyContent: 'space-between',
                            width: '48vw',
                            marginBottom: 14,
                        }}
                    >
                        <Button
                            onClick={() => {
                                setRotationDeg(rotationDeg > 0 ? rotationDeg - 90 : 270);
                            }}
                            size="large"
                            shape="circle"
                            type="primary"
                            icon={<UndoOutlined />}
                        />
                        <Button
                            onClick={() => {
                                setRotationDeg(rotationDeg < 270 ? rotationDeg + 90 : 0);
                            }}
                            size="large"
                            shape="circle"
                            type="primary"
                            icon={<RedoOutlined />}
                        />
                    </div>
                ) : null}
                <ImageGallery
                    ref={imageGalleryRef}
                    onClick={e => openImage(e)}
                    items={parsedImages}
                    showPlayButton={false}
                    showBullets
                    showFullscreenButton={parsedImages.length > 0}
                    additionalClass={`rotate-${rotationDeg}`}
                />
                <Upload onDelete={onDeleteImage} />
            </div>
            <AuthorizedDoctorsList />
        </div>
    );
};

ImagesSection.propTypes = {
    user: PropTypes.object.isRequired,
    deleteImageAsync: PropTypes.func.isRequired,
};

const stateToProps = state => ({
    user: state.auth.user,
});

const dispatchToProps = dispatch => bindActionCreators(
    {
        deleteImageAsync,
    },
    dispatch,
);

export default connect(stateToProps, dispatchToProps)(ImagesSection);
