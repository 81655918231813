import React from 'react';
import PropTypes from 'prop-types';

export const AmericanExpress = ({ style }) => (
    <img
        style={style}
        src="/assets/images/cards/AmericanExpress.svg"
        alt="AmericanExpress-logo"
    />
);
export const ApplePay = ({ style }) => (
    <img
        style={style}
        src="/assets/images/cards/ApplePay.svg"
        alt="ApplePay-logo"
    />
);
export const DinnersClub = ({ style }) => (
    <img
        style={style}
        src="/assets/images/cards/DinnersClub.svg"
        alt="DinnersClub-logo"
    />
);
export const Discover = ({ style }) => (
    <img
        style={style}
        src="/assets/images/cards/Discover.svg"
        alt="Discover-logo"
    />
);
export const Gpay = ({ style }) => (
    <img
        style={style}
        src="/assets/images/cards/Gpay.svg"
        alt="Gpay-logo"
    />
);
export const JCB = ({ style }) => (
    <img
        style={style}
        src="/assets/images/cards/JCB.png"
        alt="JCB-logo"
    />
);
export const Maestro = ({ style }) => (
    <img
        style={style}
        src="/assets/images/cards/Maestro.svg"
        alt="Maestro-logo"
    />
);
export const MasterCard = ({ style }) => (
    <img
        style={style}
        src="/assets/images/cards/MasterCard.svg"
        alt="MasterCard-logo"
    />
);
export const Visa = ({ style }) => (
    <img
        style={style}
        src="/assets/images/cards/Visa.svg"
        alt="Visa-logo"
    />
);

export const PlayStore = ({ style }) => (
    <img
        style={style}
        src="/assets/images/cards/google-play-store.svg"
        alt="Playstore-logo"
    />
);


export const AllCards = [
    Visa, MasterCard, AmericanExpress, Maestro, Gpay, ApplePay, DinnersClub, Discover, JCB,
];

AmericanExpress.propTypes = {
    style: PropTypes.object,
};
AmericanExpress.defaultProps = {
    style: {},
};
ApplePay.propTypes = {
    style: PropTypes.object,
};
ApplePay.defaultProps = {
    style: {},
};
DinnersClub.propTypes = {
    style: PropTypes.object,
};
DinnersClub.defaultProps = {
    style: {},
};
Discover.propTypes = {
    style: PropTypes.object,
};
Discover.defaultProps = {
    style: {},
};
Gpay.propTypes = {
    style: PropTypes.object,
};
Gpay.defaultProps = {
    style: {},
};
JCB.propTypes = {
    style: PropTypes.object,
};
JCB.defaultProps = {
    style: {},
};
Maestro.propTypes = {
    style: PropTypes.object,
};
Maestro.defaultProps = {
    style: {},
};
MasterCard.propTypes = {
    style: PropTypes.object,
};
MasterCard.defaultProps = {
    style: {},
};
Visa.propTypes = {
    style: PropTypes.object,
};
Visa.defaultProps = {
    style: {},
};

PlayStore.propTypes = {
    style: PropTypes.object,
};

PlayStore.defaultProps = {
    style: {},
};
