import React from 'react';
import PropTypes from 'prop-types';
import {
    Modal, Button, Input, Form,
} from 'antd';
import { MailOutlined } from '@ant-design/icons';
import { getLang } from '../../config/helpers';
import TEXTS from './_texts/index.texts';

const lang = getLang();


const PasswordRecovery = (props) => {
    const {
        visible,
        loading,
        handleOk,
        handleCancel,
    } = props;

    const [form] = Form.useForm();

    const handleSubmit = () => {
        form.validateFields().then((values) => {
            handleOk(values.email);
        });
    };

    return (
        <Modal
            visible={visible}
            onCancel={handleCancel}
            title={TEXTS.modalTitle[lang]}
            footer={[
                <Button key="back" onClick={handleCancel}>
                    {TEXTS.buttonCancel[lang]}
                </Button>,
                <Button
                    key="submit"
                    type="primary"
                    loading={loading}
                    onClick={() => {
                        handleSubmit();
                    }}
                >
                    {TEXTS.buttonSubmit[lang]}
                </Button>,
            ]}
        >
            <Form
                form={form}
                name="pw-recovery"
            >
                <Form.Item
                    name="email"
                    rules={[
                        {
                            type: 'email',
                            message: TEXTS.inputErrorTypeEmail[lang],
                        },
                        {
                            required: true,
                            message: TEXTS.inputErrorRequired[lang],
                        },
                    ]}
                >
                    <Input
                        prefix={<MailOutlined className="site-form-item-icon" />}
                        placeholder="Email"
                    />
                </Form.Item>
            </Form>
        </Modal>
    );
};

PasswordRecovery.propTypes = {
    visible: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
    handleOk: PropTypes.func.isRequired,
    handleCancel: PropTypes.func.isRequired,
};

export default PasswordRecovery;
