/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    Form, Input, Typography, Button, message, Checkbox,
} from 'antd';
import {
    CardNumberElement,
    CardExpiryElement,
    CardCvcElement,
    useStripe,
    useElements,
} from '@stripe/react-stripe-js';
import { AllCards } from '../../components/Icons/cards';
import CGU from '../../components/CGU';
import { getLang } from '../../config/helpers';
import { setStripeCustomerAsync } from '../../redux/actions/auth';
import { getClientSecret } from '../../api/consultations';
import TEXTS from './_texts/CheckoutForm.texts';
import './styles.css';

const lang = getLang();
const { Title } = Typography;

const prepareWidth = (i) => {
    switch (i) {
        case 6:
            return 60;
        case 7:
            return 45;
        default:
            return 30;
    }
};

const stripeInputStyles = {
    base: {
        fontSize: '16px',
        color: 'rgba(0, 0, 0, 0.65)',
        '::placeholder': {
            color: '#d9d9d9',
        },
    },
};

const CheckoutForm = ({
    user, event, setStripeCustomerAsync, handleNext, autoriseCGU, autorisePPD, setAutoriseCGU, setAutorisePPD,
}) => {
    const stripe = useStripe();
    const elements = useElements();
    const [loading, setLoading] = useState(false);
    const [name, setName] = useState('');
    const [cguOpen, setCguOpen] = useState(false);
    // const [ruleOne, setRuleOne] = useState(false);

    console.log({ cguOpen });

    useEffect(() => {
        if (!user.customerId) {
            setStripeCustomerAsync();
        }
    }, [user]);


    const handleSubmit = async () => {
        if (!name) {
            message.error(TEXTS.input1stItemError[lang]);
        } else if (!autoriseCGU || !autorisePPD) {
            message.error(TEXTS.autoriseItemError[lang]);
        } else {
            setLoading(true);
            try {
                const res = await getClientSecret(event._id);
                if (res.data) {
                    if (!stripe || !elements) {
                        return;
                    }
                    const card = elements.getElement(CardNumberElement);

                    const { error, setupIntent } = await stripe.confirmCardSetup(res.data, {
                        payment_method: {
                            card,
                            billing_details: {
                                email: user.email,
                                name,
                            },
                        },
                    });

                    if (error) {
                        message.error(error.message);
                    } else {
                        handleNext(setupIntent);
                    }
                }
            } catch (err) {
                // Pass
            }
        }


        setLoading(false);
    };

    return (
        <div className="booking-checkout-form">
            <div style={{ marginBottom: 20 }}>
                <Title style={{ fontSize: 16 }} level={4}>
                    {TEXTS.acceptedCards[lang]}
                </Title>
                <div
                    style={{
                    }}
                >
                    {AllCards.map((CardComponent, i) => (
                        <CardComponent
                            key={i.toString()}
                            style={{ width: prepareWidth(i), height: 30, margin: '0px 2px' }}
                        />
                    ))}
                </div>
            </div>
            <Form size="large" id="book-telec-form">
                <Form.Item>
                    <Input onChange={setName} placeholder={TEXTS.input1stItem[lang]} />
                </Form.Item>
                <Form.Item>
                    <CardNumberElement
                        className="ant-input ant-input-lg stripe-input"
                        placeholder={TEXTS.input2ndItem[lang]}
                        options={{ style: stripeInputStyles }}
                    />
                </Form.Item>
                <div className="flex">
                    <Form.Item style={{ flex: 1 }}>
                        <CardExpiryElement
                            className="ant-input ant-input-lg stripe-input"
                            placeholder="MM/YY"
                            options={{ style: stripeInputStyles }}
                        />
                    </Form.Item>
                    <Form.Item style={{ flex: 1 }}>
                        <CardCvcElement
                            className="ant-input ant-input-lg stripe-input"
                            placeholder="CVV"
                            options={{ style: stripeInputStyles }}
                        />
                    </Form.Item>
                </div>
                {/* <Form.Item>
                        <Checkbox onChange={()=>setRuleOne(!ruleOne)}  >Autoriser AeroHealth à enregistrer ce moyen de
                        paiement pour mes prochaines consultations en video.</Checkbox>
                    </Form.Item> */}
                <div className="ant-checkbox-form">
                    <Form.Item>
                        <Checkbox onChange={() => setAutoriseCGU(!autoriseCGU)}>
                            J&apos;accepte les
                            {' '}
                            <strong onClick={() => { setCguOpen(true); }}>Conditions d&apos;utilisation</strong>
                            {' '}
                            de AeroHealth.ai et je consens à
                            réaliser une consultation vidéo.
                        </Checkbox>
                    </Form.Item>
                    <Form.Item>
                        <Checkbox onChange={() => setAutorisePPD(!autorisePPD)}>
                            J&apos;accepte la
                            {' '}
                            <strong onClick={() => { setCguOpen(true); }}>Politique de protection des données</strong>
                        </Checkbox>
                    </Form.Item>
                </div>
            </Form>
            <Button
                type="primary"
                size="large"
                className="center-button"
                onClick={handleSubmit}
                style={{ marginBottom: 24 }}
                loading={loading}
                disabled={!stripe}
            >
                {TEXTS.submitButton[lang]}
            </Button>
            <CGU open={cguOpen} handleClose={() => setCguOpen(false)} />
        </div>
    );
};


CheckoutForm.propTypes = {
    user: PropTypes.object.isRequired,
    event: PropTypes.object.isRequired,
    setStripeCustomerAsync: PropTypes.func.isRequired,
    handleNext: PropTypes.func.isRequired,
};

const stateToProps = state => ({
    user: state.auth.user,
    event: state.consultations.selectedEvent,
});

const dispatchToProps = dispatch => bindActionCreators(
    {
        setStripeCustomerAsync,
    },
    dispatch,
);

export default connect(stateToProps, dispatchToProps)(CheckoutForm);
