/* eslint-disable no-underscore-dangle */
import React from 'react';
import { useDispatch } from 'react-redux';
import {
    Card,
    Button,
    Result,
} from 'antd';
import { SmileOutlined } from '@ant-design/icons';

import { checkAuthAsync } from '../../redux/actions/auth';
import { getLang } from '../../config/helpers';
import TEXTS from './_texts/index.texts';
import './styles.css';

const lang = getLang();

const EmailVerification = () => {
    const dispatch = useDispatch();

    return (
        <Card className="initial-config-card">
            <Result
                icon={<SmileOutlined />}
                style={{ paddingBottom: 12 }}
                title={TEXTS.step0ResultTitle[lang]}
                status="success"
                subTitle={TEXTS.step0ResultSubTitle[lang]}
                extra={(
                    <Button
                        size="large"
                        onClick={() => {
                            dispatch(checkAuthAsync(true));
                        }}
                        type="primary"
                    >
                        {TEXTS.step0StartButton[lang]}
                    </Button>
                )}
            />
        </Card>
    );
};

export default EmailVerification;
