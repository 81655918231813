/* eslint-disable no-underscore-dangle */
import React from 'react';
import { Upload, Button, message } from 'antd';
import { DeleteOutlined, UploadOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { checkAuthAsync, deleteImageAsync } from '../../redux/actions/auth';
import { getLang } from '../../config/helpers';
import TEXTS from './_texts/upload.texts';
import { addImages } from '../../api/images';

const lang = getLang();

const UploadImage = ({
    user, checkAuthAsync, onDelete,
}) => {
    const uploadImage = async (file) => {
        try {
            await addImages(user._id, 'photos', file);
            checkAuthAsync();
            message.success(TEXTS.uploadSuccessMSG[lang]);
        } catch (err) {
            message.error(TEXTS.uploadErrorMSG[lang]);
        }
    };

    const showDelete = user && user.photos.length;

    return (
        <div className="flex" style={{ justifyContent: 'space-around' }}>
            {showDelete ? (
                <Button
                    type="danger"
                    size="large"
                    style={{ marginTop: 10 }}
                    onClick={() => onDelete()}
                >
                    <DeleteOutlined style={{ marginRight: 5 }} />
                    {TEXTS.deleteButton[lang]}
                </Button>
            ) : null}
            <Upload
                showUploadList={false}
                customRequest={({ file }) => {
                    setTimeout(() => {
                        uploadImage(file);
                    }, 0);
                }}
                listType="text"
            >
                <Button block type="primary" size="large" style={{ marginTop: 10 }}>
                    <UploadOutlined style={{ marginRight: 5 }} />
                    {TEXTS.uploadButton[lang]}
                </Button>
            </Upload>
        </div>
    );
};

UploadImage.propTypes = {
    user: PropTypes.object.isRequired,
    checkAuthAsync: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
};

const stateToProps = state => ({
    user: state.auth.user,
});

const dispatchToProps = dispatch => bindActionCreators(
    {
        checkAuthAsync,
        deleteImageAsync,
    },
    dispatch,
);

export default connect(stateToProps, dispatchToProps)(UploadImage);
