export default {
    maleCard: {
        fr: 'Mâle',
        en: 'Male',
    },
    FemaleCard: {
        fr: 'Femelle',
        en: 'Female',
    },
    CustomCard: {
        fr: 'Personnalisé',
        en: 'Custom',
    },
};
