/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Typography } from 'antd';
import { setConsultationsListAsync } from '../../redux/actions/consultations';
import TelecCard from '../../components/TelecCard';
import { getLang } from '../../config/helpers';
import TelecHistory from './telecHistory';
import TEXTS from './_texts/index.texts';

const lang = getLang();
const { Title } = Typography;

const MyBookings = ({ user, getConsultations, consultationsList }) => {
    useEffect(() => {
        getConsultations();
    }, [user]);

    return (
        <div>
            <Title level={3} style={{ textAlign: 'center' }}>
                {!consultationsList.length
                    ? `${TEXTS.emptyTitle[lang]}`
                    : consultationsList.length === 1
                        ? `${TEXTS.title1[lang]} ${consultationsList.length} ${TEXTS.title2Singular[lang]}`
                        : `${TEXTS.title1[lang]} ${consultationsList.length} ${TEXTS.title2Plural[lang]}`}
            </Title>
            <div
                style={{
                    margin: '50px auto',
                    alignItems: 'baseline',
                    justifyContent: 'center',
                }}
                className="flex flex-wrap"
            >
                {consultationsList.map(item => (
                    <TelecCard telec={item} key={item._id} />
                ))}
            </div>
            <div
                style={{
                    margin: '50px 10%',
                }}
            >
                <TelecHistory />
            </div>
        </div>
    );
};

MyBookings.propTypes = {
    user: PropTypes.object.isRequired,
    getConsultations: PropTypes.func.isRequired,
    consultationsList: PropTypes.array.isRequired,
};

const stateToProps = state => ({
    user: state.auth.user,
    consultationsList: state.consultations.consultationsList,
});

const dispatchToProps = dispatch => bindActionCreators(
    {
        getConsultations: setConsultationsListAsync,
    },
    dispatch,
);

export default connect(stateToProps, dispatchToProps)(MyBookings);
