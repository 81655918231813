import React from 'react';
import {
    AppstoreTwoTone,
    HeartTwoTone,
    ProfileTwoTone,
    SettingTwoTone,
    CameraOutlined,
    FolderOpenOutlined,
} from '@ant-design/icons';

const twoToneColor = '#ff6000';

const config = {
    dashboard: {
        name: 'dashboard',
        title: 'Tableau de bord',
        icon: style => (<AppstoreTwoTone twoToneColor={twoToneColor} style={style} />),
        route: '/',
    },
    consultations: {
        name: 'consultations',
        title: 'Mes téléconsultations',
        icon: style => (<HeartTwoTone twoToneColor={twoToneColor} style={style} />),
        route: '/consultations',
    },
    images: {
        name: 'images',
        title: 'Mes photos',
        icon: style => (
            <CameraOutlined style={{ ...style, color: twoToneColor }} />
        ),
        route: '/images',
    },
    documents: {
        name: 'documents',
        title: 'Mes documents',
        icon: style => (
            <FolderOpenOutlined style={{ ...style, color: twoToneColor }} />
        ),
        route: '/documents',
    },
    profile: {
        name: 'profile',
        title: 'Mon profil',
        icon: style => (<ProfileTwoTone twoToneColor={twoToneColor} style={style} />),
        route: '/profile',
    },
    config: {
        name: 'config',
        title: 'Configuration initiale',
        icon: style => (<SettingTwoTone twoToneColor={twoToneColor} style={style} />),
        route: 'config',
    },
};

export const mapRouteToElement = {
    '/': config.dashboard,
    '/consultations': config.consultations,
    '/documents': config.documents,
    '/images': config.images,
    '/profile': config.profile,
    '/config': config.config,
};

export default config;
