import React, { useEffect } from 'react';
import {
    Form, Input, Radio, Button, message,
} from 'antd';
import { DateTime } from 'luxon';
import { useDispatch, useSelector } from 'react-redux';
import ProfilePicture from '../../components/ProfilePicture';
import formRules from '../../helpers/formRules';
import CustomDatePicker, { dayMonthFormatter } from '../../components/CustomDatePicker';
import PhoneNumberField from '../../components/PhoneNumberField';
import { updateUserAsync } from '../../redux/actions/auth';
import './styles.css';

const inputWidth = 280;

// NOTE Change this to config
const genderRadio = [
    { label: 'Homme', value: 'male' },
    { label: 'Femme', value: 'female' },
    { label: 'Autre', value: 'other' },
];

const Identity = () => {
    const user = useSelector(state => state.auth.user);
    const [form] = Form.useForm();

    const dispatch = useDispatch();

    const handleUpdate = (values) => {
        const birthDate = DateTime.fromISO(
            `${values.year}-${dayMonthFormatter(values.month)}-${dayMonthFormatter(
                values.day,
            )}`,
        );
        const phoneNumber = `${values.prefix} ${values.phone}`;
        dispatch(updateUserAsync({ ...values, phoneNumber, birthDate }));
        message.success('Modifications effectuée avec succès');
    };

    useEffect(() => {
        Object.keys(user).forEach((key) => {
            if (key === 'birthDate') {
                const birthDate = DateTime.fromISO(user[key]);
                form.setFieldsValue({
                    day: birthDate.day,
                    month: birthDate.month,
                    year: birthDate.year,
                });
            } else if (key === 'phoneNumber') {
                const splittedPhoneNumber = user[key].split(' ');
                form.setFieldsValue({
                    prefix: splittedPhoneNumber[0],
                    phone: splittedPhoneNumber[1],
                });
            } else {
                form.setFieldsValue({ [key]: user[key] });
            }
        });
    }, []);

    return (
        <div className="flex">
            <div className="half-width m-auto">
                <ProfilePicture />
            </div>
            <div className="half-width m-top-medium flex justify-c-center">
                <Form
                    onFinish={handleUpdate}
                    form={form}
                    layout="vertical"
                >
                    <Form.Item
                        label="Nom"
                        name="lastName"
                        rules={[formRules.required]}
                        style={{ maxWidth: inputWidth }}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Prénom"
                        name="firstName"
                        rules={[formRules.required]}
                        style={{ maxWidth: inputWidth }}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Numéro de sécurité sociale"
                        name="securityNumber"
                        rules={[formRules.required]}
                        style={{ maxWidth: inputWidth }}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item label="Sexe" name="gender" rules={[formRules.required]}>
                        <Radio.Group>
                            {genderRadio.map(radioItem => (
                                <Radio value={radioItem.value}>{radioItem.label}</Radio>
                            ))}
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item label="Date de naissance">
                        <CustomDatePicker form={form} />
                    </Form.Item>
                    <Form.Item label="Numéro de téléphone">
                        <PhoneNumberField />
                    </Form.Item>

                    <Form.Item>
                        <Button
                            htmlType="submit"
                            type="primary"
                            ghost
                        >
                            Mettre à jour
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    );
};

export default Identity;
