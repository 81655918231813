import React, { useEffect, useState } from 'react';
import { Form, InputNumber, Select } from 'antd';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import { months } from '../../config/helpers';
import FORM_RULES from '../../helpers/formRules';

const { Option } = Select;
export const dayMonthFormatter = a => (a < 10 ? `0${a}` : a);


const CustomDatePicker = ({ form }) => {
    const [maxDays, setMaxDays] = useState(31);
    const [month, setMonth] = useState(null);
    const [year, setYear] = useState(null);

    useEffect(() => {
        if (month) {
            const formatedMonth = dayMonthFormatter(month);
            let max;
            if (year) {
                max = DateTime.fromISO(`${year}-${formatedMonth}`).daysInMonth;
            } else {
                max = DateTime.fromISO(`2020-${formatedMonth}`).daysInMonth;
            }
            setMaxDays(max);
        }
    }, [month]);


    useEffect(() => {
        if (month) {
            form.validateFields(['day']);
        }
    }, [maxDays]);


    return (
        <div className="custom-birthdate-inputs">
            <Form.Item
                style={{ width: 'min-content' }}
                name="day"
                rules={[FORM_RULES.required]}
            >
                <InputNumber
                    placeholder="Jour"
                    size="large"
                    min={1}
                />
            </Form.Item>
            <Form.Item
                style={{ width: 'min-content' }}
                rules={[FORM_RULES.required]}
                name="month"
            >
                <Select
                    onChange={setMonth}
                    value={month}
                    placeholder="Mois"
                    size="large"
                >
                    {months.map((month, i) => (
                        <Option key={month} value={i + 1}>
                            {month}
                        </Option>
                    ))}
                </Select>
            </Form.Item>
            <Form.Item
                style={{ width: 'min-content' }}
                rules={[
                    FORM_RULES.required,
                    // { TODO see if neeeded
                    //     validator: (_, value) => (value && value >= 1940 && value <= 2006
                    //         ? Promise.resolve()
                    //         : Promise.reject(TEXTS.ageLimit[lang])),
                    // },
                ]}
                name="year"
            >
                <InputNumber
                    value={year}
                    onChange={setYear}
                    placeholder="Année"
                    size="large"
                />
            </Form.Item>
        </div>
    );
};


CustomDatePicker.propTypes = {
    form: PropTypes.any.isRequired,
};


export default CustomDatePicker;
