import React from 'react';
// import PropTypes from 'prop-types';
import {
    YoutubeOutlined,
    FacebookOutlined,
    InstagramOutlined,
    LinkedinOutlined,
    GlobalOutlined,
} from '@ant-design/icons';

export default {
    youtube: <YoutubeOutlined style={{ fontSize: 18, color: '#ff4d4f' }} />,
    facebook: <FacebookOutlined style={{ fontSize: 18, color: '#40a9ff' }} />,
    instagram: <InstagramOutlined style={{ fontSize: 18, color: '#f34c53' }} />,
    linkedin: <LinkedinOutlined style={{ fontSize: 18, color: '#40a9ff' }} />,
    website: <GlobalOutlined style={{ fontSize: 18, color: '#40a9ff' }} />,
};
