/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from 'react';
import {
    Radio, message, Button, Collapse, Popconfirm,
} from 'antd';
import {
    FileOutlined,
    CloudDownloadOutlined,
    DeleteOutlined,
} from '@ant-design/icons';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { deleteDocumentAsync, addDocumentAsync } from '../../redux/actions/auth';
import { loadDocument } from '../../api/documents';
import UploadModal from './UploadModal';
import './styles.css';
import AuthorizedDoctorsList from './authorizedDoctorsList';
import TEXTS from './_texts/upload.texts';
import { getLang } from '../../config/helpers';
import { addListDocuments, deleteDocument } from '../../api/documents';

const { Panel } = Collapse;
// TODO Refactor document categories
const parseDocuments = (filename, userId, categorie, _id) => ({
    filename,
    filepath: loadDocument(userId, 'documents', filename),
    categorie,
    _id,
});

const lang = getLang();

const Documents = ({ user, deleteDocumentAsync, addDocumentAsync }) => {
    const [parsedDocuments, setParsedDocuments] = useState([]);
    const [selectedFileIndex, setSelectedFileIndex] = useState(null);
    const [displayModal, setDisplayModal] = useState(false);
    const [listedCategorie, setListedCategorie] = useState([]);

    useEffect(() => {
        if (user) {
            const rawDocs = user.documents || [];
            const docs = [];
            rawDocs.map((doc) => {
                const { categorie, document, _id } = doc;
                docs.push(parseDocuments(document, user._id, categorie, _id));
            });
            setParsedDocuments(docs);
        }
    }, [user]);

    useEffect(() => {
        const result = [
            ...parsedDocuments
                .reduce((r, {
                    categorie, _id, filename, filepath,
                }) => {
                    r.has(categorie)
            || r.set(categorie, {
                categorie,
                parsedDocuments: [],
            });

                    r.get(categorie).parsedDocuments.push({ _id, filename, filepath });

                    return r;
                }, new Map())
                .values(),
        ];

        setListedCategorie(result);
    }, [parsedDocuments]);

    const onDeleteDocuments = async (doc) => {
        await deleteDocument(user._id, doc);
        deleteDocumentAsync(doc._id);
        message.success(TEXTS.deleteMessage[lang]);
    };

    const onCreate = async (values) => {
        try {
            const data = await addListDocuments(user._id, 'documents', values);
            setDisplayModal(false);
            addDocumentAsync(data.data.documents);
            message.success(TEXTS.uploadSuccessMSG[lang]);
        } catch (err) {
            console.log(err);
            message.error(TEXTS.uploadErrorMSG[lang]);
        }
    };

    return (
        <div className="flex documents-container">
            <div
                style={{
                    marginTop: 10, marginRight: 5, marginLeft: 0, width: '66%',
                }}
            >
                <Button
                    type="primary"
                    size="middle"
                    style={{ marginTop: -'20px', marginBottom: '20px' }}
                    onClick={() => setDisplayModal(true)}
                    style={{ width: '100%' }}
                >
                    Ajouter un document
                </Button>
                <Collapse defaultActiveKey={[]}>
                    {listedCategorie
                        ? listedCategorie.map((grouped, index) => (
                            <Panel header={grouped.categorie} key={index}>
                                <Radio.Group
                                    onChange={({ target }) => {
                                        setSelectedFileIndex(target.value);
                                    }}
                                    style={{
                                        width: '100% !important',
                                        display: 'flex !important',
                                        flexDirection: 'row !important',
                                    }}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            flexWrap: 'wrap',
                                        }}
                                    >
                                        {grouped.parsedDocuments.map((doc, docIndex) => {
                                            const isSelected = selectedFileIndex != null
                                                ? doc._id === selectedFileIndex._id
                                                : null;
                                            const Icon = isSelected
                                                ? CloudDownloadOutlined
                                                : FileOutlined;
                                            return (
                                                <Radio.Button
                                                    value={doc}
                                                    className="doc-file-radio"
                                                >
                                                    <p>{doc.filename}</p>
                                                    <Icon
                                                        style={{
                                                            width: '100%',
                                                            fontSize: '4em',
                                                            marginBottom: '0.2em',
                                                        }}
                                                        onClick={(e) => {
                                                            if (isSelected) {
                                                                e.preventDefault();
                                                                window.open(
                                                                    parsedDocuments[docIndex].filepath,
                                                                );
                                                            }
                                                        }}
                                                    />
                                                    <Popconfirm
                                                        title="Etes vous sur de supprimer ce document ?"
                                                        onConfirm={() => onDeleteDocuments(doc)}
                                                        onCancel={() => message.error(
                                                            'Vous avez annuler la supression',
                                                        )
                                                        }
                                                        okText="Yes"
                                                        cancelText="No"
                                                    >
                                                        <Button
                                                            type="danger"
                                                            size="small"
                                                            style={{ marginTop: 10 }}
                                                        >
                                                            <DeleteOutlined style={{ marginRight: 5 }} />
                                                            Supprimer
                                                            {/* {TEXTS.deleteButton[lang]} */}
                                                        </Button>
                                                    </Popconfirm>
                                                </Radio.Button>
                                            );
                                        })}
                                    </div>
                                </Radio.Group>
                            </Panel>
                        ))
                        : "Vous n'avez pas encore ajotué des documents"}
                </Collapse>


                <UploadModal
                    visible={displayModal}
                    onCreate={onCreate}
                    fileType="document"
                    onCancel={() => {
                        setDisplayModal(false);
                    }}
                />
                {/* <Upload onDelete={onDeleteDocuments} fileType="document" />  */}
            </div>
            <AuthorizedDoctorsList />
        </div>
    );
};

Documents.propTypes = {
    user: PropTypes.object.isRequired,
    deleteDocumentAsync: PropTypes.func.isRequired,
    addDocumentAsync: PropTypes.func.isRequired,
};

const stateToProps = state => ({
    user: state.auth.user,
});

const dispatchToProps = dispatch => bindActionCreators(
    {
        deleteDocumentAsync,
        addDocumentAsync,
    },
    dispatch,
);

export default connect(stateToProps, dispatchToProps)(Documents);
