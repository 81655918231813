import React from 'react';
import { Layout, Menu, Divider } from 'antd';
import { MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import items from './config';
import { navigateTo } from '../../history';
import useWindowSize from '../../hooks/useWindowSize';
import { isConfigComplete } from '../../helpers/functions';
import './styles.css';

const { Item } = Menu;
const { Sider } = Layout;

const Sidebar = ({ currentRoute, user }) => {
    const size = useWindowSize();

    const [isPortrait, setIsPortrait] = React.useState(size.width < size.height);
    const [isCollapsed, setIsCollapsed] = React.useState(isPortrait);

    React.useEffect(() => {
        if (size.width < size.height) {
            setIsPortrait(true);
            setIsCollapsed(true);
        } else {
            setIsPortrait(false);
            setIsCollapsed(false);
        }
    }, [size]);

    const handleCollapse = value => setIsCollapsed(value);

    return (
        <>
            {isPortrait ? (
                <div className="sidebar-collapse-trigger">
                    {React.createElement(
                        isCollapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
                        {
                            className: 'trigger',
                            onClick: () => setIsCollapsed(!isCollapsed),
                        },
                    )}
                </div>
            ) : null}
            <Sider
                collapsible={isPortrait}
                collapsed={isCollapsed}
                trigger={null}
                onCollapse={handleCollapse}
                width={280}
                className={`sidebar${isPortrait ? ' collapsible' : ''}`}
            >
                <div className="p-medium">
                    <img
                        src="ah-logo.svg"
                        alt="logo"
                        className="full-width center-button"
                    />
                </div>
                <Menu
                    className="sidebar"
                    style={{ marginTop: 14 }}
                    selectedKeys={[currentRoute]}
                >
                    {Object.values(items).map(
                        item => item.name !== 'config' && (
                        <>
                            <Item
                                disabled={!isConfigComplete(user) || !user.email_verified}
                                className="sidebar-menu-item flex justify-c-start p-left-medium"
                                key={item.route}
                                icon={item.icon({ fontSize: 24 })}
                                onClick={() => {
                                    navigateTo(item.route);
                                }}
                                style={{ fontSize: 16 }}
                            >
                                {item.title}
                            </Item>
                            <Divider />
                        </>
                        ),
                    )}
                </Menu>
            </Sider>
        </>
    );
};

Sidebar.propTypes = {
    currentRoute: PropTypes.string.isRequired,
    user: PropTypes.object.isRequired,
};

const stateToProps = state => ({
    user: state.auth.user,
});

export default connect(stateToProps)(Sidebar);
