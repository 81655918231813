import { DateTime } from 'luxon';
import { getLang } from '../../../../config/helpers';

const lang = getLang();

export const formatDateForTelec = date => DateTime.fromMillis(Number(date))
    .setLocale(lang)
    .toLocaleString(DateTime.DATETIME_FULL);

export default {
    cancelledTitle: {
        fr: 'Téléconsultation annulée',
        en: 'Cancelled teleconsultation',
    },
    cancelledDescripton: {
        fr: info => `Le médecin ${
            info[0]
        } a annulé la téléconsultation prévue pour le ${formatDateForTelec(
            info[1],
        )}`,
        en: info => `Your doctor ${info[0]} have cancelled the teleconsultation planned for the ${formatDateForTelec(
            info[1],
        )}`,
    },
};
