/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    Card, Button, Typography, message,
} from 'antd';
import {
    UserOutlined,
    RightOutlined,
} from '@ant-design/icons';
import { getFullName } from '../../helpers/functions';
import './styles.css';
import MediaControls from './mediaControls';
import { getLang } from '../../config/helpers';
import TEXTS from './_texts/localStreamPreview.texts';

const lang = getLang();
const { Title, Text } = Typography;

const getLocalStream = async (setLocalStream) => {
    try {
        const localStream = await navigator.mediaDevices.getUserMedia({
            video: true,
            audio: true,
        });
        setLocalStream(localStream);
    } catch (err) {
        switch (err.name) {
            case 'NotFoundError':
                message.error(TEXTS.notFoundError[lang]);
                break;
            case 'NotAllowedError':
                message.error(TEXTS.notAllowedError[lang]);
                break;
            default:
                break;
        }
    }
};

const LocalStreamPreview = ({
    localStream,
    setLocalStream,
    handleStart,
    activeTelec,
}) => {
    const handleCheckBox = (option) => {
        if (option === 'mic') {
            localStream.getAudioTracks()[0].enabled = !localStream.getAudioTracks()[0]
                .enabled;
        } else {
            localStream.getVideoTracks()[0].enabled = !localStream.getVideoTracks()[0]
                .enabled;
        }
    };

    return (
        <div style={{ marginTop: 50 }}>
            <div
                className="flex-column"
                style={{ marginBottom: 24, textAlign: 'center' }}
            >
                {localStream ? null : (
                    <Title level={4}>
                        {TEXTS.allowAccess[lang]}
                    </Title>
                )}
                <Text type="secondary">{TEXTS.startTitle[lang]}</Text>
                <Text>{`Dr. ${getFullName(activeTelec.doctor)}`}</Text>
            </div>

            <Card style={{ margin: '10px auto', width: 'max-content', padding: '0 50px' }}>
                <div style={{ textAlign: 'center' }}>
                    {localStream ? (
                        <video id="local-video-preview" autoPlay />
                    ) : (
                        <UserOutlined
                            onClick={() => {
                                getLocalStream(setLocalStream);
                            }}
                            style={{ cursor: 'pointer', fontSize: 80, marginBottom: 24 }}
                        />
                    )}
                </div>

                {localStream ? (
                    <div className="flex" style={{ justifyContent: 'center' }}>
                        <MediaControls handleCheckBox={handleCheckBox} canHangup={false} />
                        <Button
                            onClick={handleStart}
                            className="vid-preview-item"
                            type="primary"
                        >
                            {TEXTS.startButton[lang]}
                            <RightOutlined />
                        </Button>
                    </div>
                ) : (
                    <Button
                        className="center-button"
                        onClick={() => {
                            getLocalStream(setLocalStream);
                        }}
                        type="primary"
                    >
                        {TEXTS.authorizeButton[lang]}
                    </Button>
                )}
            </Card>
        </div>
    );
};

LocalStreamPreview.propTypes = {
    localStream: PropTypes.object,
    setLocalStream: PropTypes.func.isRequired,
    handleStart: PropTypes.func.isRequired,
    activeTelec: PropTypes.object.isRequired,
};

LocalStreamPreview.defaultProps = {
    localStream: null,
};

const stateToProps = state => ({
    user: state.auth.user,
    activeTelec: state.consultations.activeTelec,
});

export default connect(stateToProps)(LocalStreamPreview);
