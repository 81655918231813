/* eslint-disable consistent-return */
import { notification } from 'antd';
import { db } from '../../../firebase';
import { getLang } from '../../../config/helpers';
import TEXTS from './_texts/index.texts';

const lang = getLang();

const notificationsListner = async (uid, cb) => {
    try {
        const docRef = db.collection('patients').doc(uid);
        const unsubscriber = await docRef.onSnapshot((doc) => {
            // eslint-disable-next-line max-len
            // TODO check the current route and refresh the data. (add cb to be executed in params for instance)

            if (doc.data() && doc.data().cancellations) {
                // eslint-disable-next-line array-callback-return
                doc.data().cancellations.map((ev) => {
                    const eventInfo = ev.split('-');
                    notification.warning({
                        message: TEXTS.cancelledTitle[lang],
                        description: TEXTS.cancelledDescripton[lang](eventInfo),
                    });
                    setTimeout(() => {}, 5000);
                });
            }
            docRef.set({ bookings: [], cancellations: [] });
            cb();
        });
        return unsubscriber;
    } catch (err) {
        // null is not a pointer of undefined
    }
};


export default notificationsListner;
