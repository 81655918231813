import commonTexts from '../../../config/common.texts';

const { inputErrorTypeEmail, inputErrorRequired, loginSubmitButton } = commonTexts;

export default {
    inputErrorTypeEmail,
    inputErrorRequired,
    inputPassword: {
        fr: 'Mot de passe',
        en: 'Password',
    },
    inputPasswordError: {
        fr: 'Veuillez saisir votre mot de passe.',
        en: 'This field is required.',
    },
    checkboxRemember: {
        fr: 'Rester connecté',
        en: 'Remember me',
    },

    buttonForgetPW: {
        fr: 'Mot de passe oublié ?',
        en: 'Forgot your password ?',
    },
    loginSubmitButton,
};
