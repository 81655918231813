export default {
    Aruba: { idd: { root: '+2', suffixes: ['97'] }, flag: '🇦🇼' },
    Afghanistan: { idd: { root: '+9', suffixes: ['3'] }, flag: '🇦🇫' },
    Angola: { idd: { root: '+2', suffixes: ['44'] }, flag: '🇦🇴' },
    Anguilla: { idd: { root: '+1', suffixes: ['264'] }, flag: '🇦🇮' },
    'Åland Islands': {
        idd: { root: '+3', suffixes: ['5818'] },
        flag: '🇦🇽',
    },
    Albania: { idd: { root: '+3', suffixes: ['55'] }, flag: '🇦🇱' },
    Andorra: { idd: { root: '+3', suffixes: ['76'] }, flag: '🇦🇩' },
    'United Arab Emirates': {
        idd: { root: '+9', suffixes: ['71'] },
        flag: '🇦🇪',
    },
    Argentina: { idd: { root: '+5', suffixes: ['4'] }, flag: '🇦🇷' },
    Armenia: { idd: { root: '+3', suffixes: ['74'] }, flag: '🇦🇲' },
    'American Samoa': {
        idd: { root: '+1', suffixes: ['684'] },
        flag: '🇦🇸',
    },
    Antarctica: { idd: { root: '', suffixes: [] }, flag: '🇦🇶' },
    'French Southern and Antarctic Lands': {
        idd: { root: '+2', suffixes: ['62'] },
        flag: '🇹🇫',
    },
    'Antigua and Barbuda': {
        idd: { root: '+1', suffixes: ['268'] },
        flag: '🇦🇬',
    },
    Australia: { idd: { root: '+6', suffixes: ['1'] }, flag: '🇦🇺' },
    Austria: { idd: { root: '+4', suffixes: ['3'] }, flag: '🇦🇹' },
    Azerbaijan: { idd: { root: '+9', suffixes: ['94'] }, flag: '🇦🇿' },
    Burundi: { idd: { root: '+2', suffixes: ['57'] }, flag: '🇧🇮' },
    Belgium: { idd: { root: '+3', suffixes: ['2'] }, flag: '🇧🇪' },
    Benin: { idd: { root: '+2', suffixes: ['29'] }, flag: '🇧🇯' },
    'Burkina Faso': { idd: { root: '+2', suffixes: ['26'] }, flag: '🇧🇫' },
    Bangladesh: { idd: { root: '+8', suffixes: ['80'] }, flag: '🇧🇩' },
    Bulgaria: { idd: { root: '+3', suffixes: ['59'] }, flag: '🇧🇬' },
    Bahrain: { idd: { root: '+9', suffixes: ['73'] }, flag: '🇧🇭' },
    Bahamas: { idd: { root: '+1', suffixes: ['242'] }, flag: '🇧🇸' },
    'Bosnia and Herzegovina': {
        idd: { root: '+3', suffixes: ['87'] },
        flag: '🇧🇦',
    },
    'Saint Barthélemy': {
        idd: { root: '+5', suffixes: ['90'] },
        flag: '🇧🇱',
    },
    'Saint Helena, Ascension and Tristan da Cunha': {
        idd: { root: '+2', suffixes: ['90', '47'] },
        flag: '🇸🇭',
    },
    Belarus: { idd: { root: '+3', suffixes: ['75'] }, flag: '🇧🇾' },
    Belize: { idd: { root: '+5', suffixes: ['01'] }, flag: '🇧🇿' },
    Bermuda: { idd: { root: '+1', suffixes: ['441'] }, flag: '🇧🇲' },
    Bolivia: { idd: { root: '+5', suffixes: ['91'] }, flag: '🇧🇴' },
    'Caribbean Netherlands': {
        idd: { root: '+5', suffixes: ['99'] },
        flag: '',
    },
    Brazil: { idd: { root: '+5', suffixes: ['5'] }, flag: '🇧🇷' },
    Barbados: { idd: { root: '+1', suffixes: ['246'] }, flag: '🇧🇧' },
    Brunei: { idd: { root: '+6', suffixes: ['73'] }, flag: '🇧🇳' },
    Bhutan: { idd: { root: '+9', suffixes: ['75'] }, flag: '🇧🇹' },
    'Bouvet Island': { idd: { root: '+4', suffixes: ['7'] }, flag: '🇧🇻' },
    Botswana: { idd: { root: '+2', suffixes: ['67'] }, flag: '🇧🇼' },
    'Central African Republic': {
        idd: { root: '+2', suffixes: ['36'] },
        flag: '🇨🇫',
    },
    Canada: { idd: { root: '+1', suffixes: [''] }, flag: '🇨🇦' },
    'Cocos (Keeling) Islands': {
        idd: { root: '+6', suffixes: ['1'] },
        flag: '🇨🇨',
    },
    Switzerland: { idd: { root: '+4', suffixes: ['1'] }, flag: '🇨🇭' },
    Chile: { idd: { root: '+5', suffixes: ['6'] }, flag: '🇨🇱' },
    China: { idd: { root: '+8', suffixes: ['6'] }, flag: '🇨🇳' },
    'Ivory Coast': { idd: { root: '+2', suffixes: ['25'] }, flag: '🇨🇮' },
    Cameroon: { idd: { root: '+2', suffixes: ['37'] }, flag: '🇨🇲' },
    'DR Congo': { idd: { root: '+2', suffixes: ['43'] }, flag: '🇨🇩' },
    'Republic of the Congo': {
        idd: { root: '+2', suffixes: ['42'] },
        flag: '🇨🇬',
    },
    'Cook Islands': { idd: { root: '+6', suffixes: ['82'] }, flag: '🇨🇰' },
    Colombia: { idd: { root: '+5', suffixes: ['7'] }, flag: '🇨🇴' },
    Comoros: { idd: { root: '+2', suffixes: ['69'] }, flag: '🇰🇲' },
    'Cape Verde': { idd: { root: '+2', suffixes: ['38'] }, flag: '🇨🇻' },
    'Costa Rica': { idd: { root: '+5', suffixes: ['06'] }, flag: '🇨🇷' },
    Cuba: { idd: { root: '+5', suffixes: ['3'] }, flag: '🇨🇺' },
    Curaçao: { idd: { root: '+5', suffixes: ['99'] }, flag: '🇨🇼' },
    'Christmas Island': {
        idd: { root: '+6', suffixes: ['1'] },
        flag: '🇨🇽',
    },
    'Cayman Islands': {
        idd: { root: '+1', suffixes: ['345'] },
        flag: '🇰🇾',
    },
    Cyprus: { idd: { root: '+3', suffixes: ['57'] }, flag: '🇨🇾' },
    Czechia: { idd: { root: '+4', suffixes: ['20'] }, flag: '🇨🇿' },
    Germany: { idd: { root: '+4', suffixes: ['9'] }, flag: '🇩🇪' },
    Djibouti: { idd: { root: '+2', suffixes: ['53'] }, flag: '🇩🇯' },
    Dominica: { idd: { root: '+1', suffixes: ['767'] }, flag: '🇩🇲' },
    Denmark: { idd: { root: '+4', suffixes: ['5'] }, flag: '🇩🇰' },
    'Dominican Republic': {
        idd: { root: '+1', suffixes: ['809', '829', '849'] },
        flag: '🇩🇴',
    },
    Algeria: { idd: { root: '+2', suffixes: ['13'] }, flag: '🇩🇿' },
    Ecuador: { idd: { root: '+5', suffixes: ['93'] }, flag: '🇪🇨' },
    Egypt: { idd: { root: '+2', suffixes: ['0'] }, flag: '🇪🇬' },
    Eritrea: { idd: { root: '+2', suffixes: ['91'] }, flag: '🇪🇷' },
    'Western Sahara': {
        idd: { root: '+2', suffixes: ['125288', '125289'] },
        flag: '🇪🇭',
    },
    Spain: { idd: { root: '+3', suffixes: ['4'] }, flag: '🇪🇸' },
    Estonia: { idd: { root: '+3', suffixes: ['72'] }, flag: '🇪🇪' },
    Ethiopia: { idd: { root: '+2', suffixes: ['51'] }, flag: '🇪🇹' },
    Finland: { idd: { root: '+3', suffixes: ['58'] }, flag: '🇫🇮' },
    Fiji: { idd: { root: '+6', suffixes: ['79'] }, flag: '🇫🇯' },
    'Falkland Islands': {
        idd: { root: '+5', suffixes: ['00'] },
        flag: '🇫🇰',
    },
    France: { idd: { root: '+3', suffixes: ['3'] }, flag: '🇫🇷' },
    'Faroe Islands': {
        idd: { root: '+2', suffixes: ['98'] },
        flag: '🇫🇴',
    },
    Micronesia: { idd: { root: '+6', suffixes: ['91'] }, flag: '🇫🇲' },
    Gabon: { idd: { root: '+2', suffixes: ['41'] }, flag: '🇬🇦' },
    'United Kingdom': {
        idd: { root: '+4', suffixes: ['4'] },
        flag: '🇬🇧',
    },
    Georgia: { idd: { root: '+9', suffixes: ['95'] }, flag: '🇬🇪' },
    Guernsey: { idd: { root: '+4', suffixes: ['4'] }, flag: '🇬🇬' },
    Ghana: { idd: { root: '+2', suffixes: ['33'] }, flag: '🇬🇭' },
    Gibraltar: { idd: { root: '+3', suffixes: ['50'] }, flag: '🇬🇮' },
    Guinea: { idd: { root: '+2', suffixes: ['24'] }, flag: '🇬🇳' },
    Guadeloupe: { idd: { root: '+5', suffixes: ['90'] }, flag: '🇬🇵' },
    Gambia: { idd: { root: '+2', suffixes: ['20'] }, flag: '🇬🇲' },
    'Guinea-Bissau': {
        idd: { root: '+2', suffixes: ['45'] },
        flag: '🇬🇼',
    },
    'Equatorial Guinea': {
        idd: { root: '+2', suffixes: ['40'] },
        flag: '🇬🇶',
    },
    Greece: { idd: { root: '+3', suffixes: ['0'] }, flag: '🇬🇷' },
    Grenada: { idd: { root: '+1', suffixes: ['473'] }, flag: '🇬🇩' },
    Greenland: { idd: { root: '+2', suffixes: ['99'] }, flag: '🇬🇱' },
    Guatemala: { idd: { root: '+5', suffixes: ['02'] }, flag: '🇬🇹' },
    'French Guiana': {
        idd: { root: '+5', suffixes: ['94'] },
        flag: '🇬🇫',
    },
    Guam: { idd: { root: '+1', suffixes: ['671'] }, flag: '🇬🇺' },
    Guyana: { idd: { root: '+5', suffixes: ['92'] }, flag: '🇬🇾' },
    'Hong Kong': { idd: { root: '+8', suffixes: ['52'] }, flag: '🇭🇰' },
    'Heard Island and McDonald Islands': {
        idd: { root: '', suffixes: [''] },
        flag: '🇭🇲',
    },
    Honduras: { idd: { root: '+5', suffixes: ['04'] }, flag: '🇭🇳' },
    Croatia: { idd: { root: '+3', suffixes: ['85'] }, flag: '🇭🇷' },
    Haiti: { idd: { root: '+5', suffixes: ['09'] }, flag: '🇭🇹' },
    Hungary: { idd: { root: '+3', suffixes: ['6'] }, flag: '🇭🇺' },
    Indonesia: { idd: { root: '+6', suffixes: ['2'] }, flag: '🇮🇩' },
    'Isle of Man': { idd: { root: '+4', suffixes: ['4'] }, flag: '🇮🇲' },
    India: { idd: { root: '+9', suffixes: ['1'] }, flag: '🇮🇳' },
    'British Indian Ocean Territory': {
        idd: { root: '+2', suffixes: ['46'] },
        flag: '🇮🇴',
    },
    Ireland: { idd: { root: '+3', suffixes: ['53'] }, flag: '🇮🇪' },
    Iran: { idd: { root: '+9', suffixes: ['8'] }, flag: '🇮🇷' },
    Iraq: { idd: { root: '+9', suffixes: ['64'] }, flag: '🇮🇶' },
    Iceland: { idd: { root: '+3', suffixes: ['54'] }, flag: '🇮🇸' },
    Israel: { idd: { root: '+9', suffixes: ['72'] }, flag: '🇮🇱' },
    Italy: { idd: { root: '+3', suffixes: ['9'] }, flag: '🇮🇹' },
    Jamaica: { idd: { root: '+1', suffixes: ['876'] }, flag: '🇯🇲' },
    Jersey: { idd: { root: '+4', suffixes: ['4'] }, flag: '🇯🇪' },
    Jordan: { idd: { root: '+9', suffixes: ['62'] }, flag: '🇯🇴' },
    Japan: { idd: { root: '+8', suffixes: ['1'] }, flag: '🇯🇵' },
    Kazakhstan: {
        idd: { root: '+7', suffixes: ['6XX', '7XX'] },
        flag: '🇰🇿',
    },
    Kenya: { idd: { root: '+2', suffixes: ['54'] }, flag: '🇰🇪' },
    Kyrgyzstan: { idd: { root: '+9', suffixes: ['96'] }, flag: '🇰🇬' },
    Cambodia: { idd: { root: '+8', suffixes: ['55'] }, flag: '🇰🇭' },
    Kiribati: { idd: { root: '+6', suffixes: ['86'] }, flag: '🇰🇮' },
    'Saint Kitts and Nevis': {
        idd: { root: '+1', suffixes: ['869'] },
        flag: '🇰🇳',
    },
    'South Korea': { idd: { root: '+8', suffixes: ['2'] }, flag: '🇰🇷' },
    Kosovo: { idd: { root: '+3', suffixes: ['83'] }, flag: '🇽🇰' },
    Kuwait: { idd: { root: '+9', suffixes: ['65'] }, flag: '🇰🇼' },
    Laos: { idd: { root: '+8', suffixes: ['56'] }, flag: '🇱🇦' },
    Lebanon: { idd: { root: '+9', suffixes: ['61'] }, flag: '🇱🇧' },
    Liberia: { idd: { root: '+2', suffixes: ['31'] }, flag: '🇱🇷' },
    Libya: { idd: { root: '+2', suffixes: ['18'] }, flag: '🇱🇾' },
    'Saint Lucia': { idd: { root: '+1', suffixes: ['758'] }, flag: '🇱🇨' },
    Liechtenstein: {
        idd: { root: '+4', suffixes: ['23'] },
        flag: '🇱🇮',
    },
    'Sri Lanka': { idd: { root: '+9', suffixes: ['4'] }, flag: '🇱🇰' },
    Lesotho: { idd: { root: '+2', suffixes: ['66'] }, flag: '🇱🇸' },
    Lithuania: { idd: { root: '+3', suffixes: ['70'] }, flag: '🇱🇹' },
    Luxembourg: { idd: { root: '+3', suffixes: ['52'] }, flag: '🇱🇺' },
    Latvia: { idd: { root: '+3', suffixes: ['71'] }, flag: '🇱🇻' },
    Macau: { idd: { root: '+8', suffixes: ['53'] }, flag: '🇲🇴' },
    'Saint Martin': { idd: { root: '+5', suffixes: ['90'] }, flag: '🇲🇫' },
    Morocco: { idd: { root: '+2', suffixes: ['12'] }, flag: '🇲🇦' },
    Monaco: { idd: { root: '+3', suffixes: ['77'] }, flag: '🇲🇨' },
    Moldova: { idd: { root: '+3', suffixes: ['73'] }, flag: '🇲🇩' },
    Madagascar: { idd: { root: '+2', suffixes: ['61'] }, flag: '🇲🇬' },
    Maldives: { idd: { root: '+9', suffixes: ['60'] }, flag: '🇲🇻' },
    Mexico: { idd: { root: '+5', suffixes: ['2'] }, flag: '🇲🇽' },
    'Marshall Islands': {
        idd: { root: '+6', suffixes: ['92'] },
        flag: '🇲🇭',
    },
    'North Macedonia': {
        idd: { root: '+3', suffixes: ['89'] },
        flag: '🇲🇰',
    },
    Mali: { idd: { root: '+2', suffixes: ['23'] }, flag: '🇲🇱' },
    Malta: { idd: { root: '+3', suffixes: ['56'] }, flag: '🇲🇹' },
    Myanmar: { idd: { root: '+9', suffixes: ['5'] }, flag: '🇲🇲' },
    Montenegro: { idd: { root: '+3', suffixes: ['82'] }, flag: '🇲🇪' },
    Mongolia: { idd: { root: '+9', suffixes: ['76'] }, flag: '🇲🇳' },
    'Northern Mariana Islands': {
        idd: { root: '+1', suffixes: ['670'] },
        flag: '🇲🇵',
    },
    Mozambique: { idd: { root: '+2', suffixes: ['58'] }, flag: '🇲🇿' },
    Mauritania: { idd: { root: '+2', suffixes: ['22'] }, flag: '🇲🇷' },
    Montserrat: { idd: { root: '+1', suffixes: ['664'] }, flag: '🇲🇸' },
    Martinique: { idd: { root: '+5', suffixes: ['96'] }, flag: '🇲🇶' },
    Mauritius: { idd: { root: '+2', suffixes: ['30'] }, flag: '🇲🇺' },
    Malawi: { idd: { root: '+2', suffixes: ['65'] }, flag: '🇲🇼' },
    Malaysia: { idd: { root: '+6', suffixes: ['0'] }, flag: '🇲🇾' },
    Mayotte: { idd: { root: '+2', suffixes: ['62'] }, flag: '🇾🇹' },
    Namibia: { idd: { root: '+2', suffixes: ['64'] }, flag: '🇳🇦' },
    'New Caledonia': {
        idd: { root: '+6', suffixes: ['87'] },
        flag: '🇳🇨',
    },
    Niger: { idd: { root: '+2', suffixes: ['27'] }, flag: '🇳🇪' },
    'Norfolk Island': {
        idd: { root: '+6', suffixes: ['72'] },
        flag: '🇳🇫',
    },
    Nigeria: { idd: { root: '+2', suffixes: ['34'] }, flag: '🇳🇬' },
    Nicaragua: { idd: { root: '+5', suffixes: ['05'] }, flag: '🇳🇮' },
    Niue: { idd: { root: '+6', suffixes: ['83'] }, flag: '🇳🇺' },
    Netherlands: { idd: { root: '+3', suffixes: ['1'] }, flag: '🇳🇱' },
    Norway: { idd: { root: '+4', suffixes: ['7'] }, flag: '🇳🇴' },
    Nepal: { idd: { root: '+9', suffixes: ['77'] }, flag: '🇳🇵' },
    Nauru: { idd: { root: '+6', suffixes: ['74'] }, flag: '🇳🇷' },
    'New Zealand': { idd: { root: '+6', suffixes: ['4'] }, flag: '🇳🇿' },
    Oman: { idd: { root: '+9', suffixes: ['68'] }, flag: '🇴🇲' },
    Pakistan: { idd: { root: '+9', suffixes: ['2'] }, flag: '🇵🇰' },
    Panama: { idd: { root: '+5', suffixes: ['07'] }, flag: '🇵🇦' },
    'Pitcairn Islands': {
        idd: { root: '+6', suffixes: ['4'] },
        flag: '🇵🇳',
    },
    Peru: { idd: { root: '+5', suffixes: ['1'] }, flag: '🇵🇪' },
    Philippines: { idd: { root: '+6', suffixes: ['3'] }, flag: '🇵🇭' },
    Palau: { idd: { root: '+6', suffixes: ['80'] }, flag: '🇵🇼' },
    'Papua New Guinea': {
        idd: { root: '+6', suffixes: ['75'] },
        flag: '🇵🇬',
    },
    Poland: { idd: { root: '+4', suffixes: ['8'] }, flag: '🇵🇱' },
    'Puerto Rico': {
        idd: { root: '+1', suffixes: ['787', '939'] },
        flag: '🇵🇷',
    },
    'North Korea': { idd: { root: '+8', suffixes: ['50'] }, flag: '🇰🇵' },
    Portugal: { idd: { root: '+3', suffixes: ['51'] }, flag: '🇵🇹' },
    Paraguay: { idd: { root: '+5', suffixes: ['95'] }, flag: '🇵🇾' },
    Palestine: { idd: { root: '+9', suffixes: ['70'] }, flag: '🇵🇸' },
    'French Polynesia': {
        idd: { root: '+6', suffixes: ['89'] },
        flag: '🇵🇫',
    },
    Qatar: { idd: { root: '+9', suffixes: ['74'] }, flag: '🇶🇦' },
    Réunion: { idd: { root: '+2', suffixes: ['62'] }, flag: '🇷🇪' },
    Romania: { idd: { root: '+4', suffixes: ['0'] }, flag: '🇷🇴' },
    Russia: {
        idd: { root: '+7', suffixes: ['3XX', '4XX', '5XX', '8XX', '9XX'] },
        flag: '🇷🇺',
    },
    Rwanda: { idd: { root: '+2', suffixes: ['50'] }, flag: '🇷🇼' },
    'Saudi Arabia': { idd: { root: '+9', suffixes: ['66'] }, flag: '🇸🇦' },
    Sudan: { idd: { root: '+2', suffixes: ['49'] }, flag: '🇸🇩' },
    Senegal: { idd: { root: '+2', suffixes: ['21'] }, flag: '🇸🇳' },
    Singapore: { idd: { root: '+6', suffixes: ['5'] }, flag: '🇸🇬' },
    'South Georgia': {
        idd: { root: '+5', suffixes: ['00'] },
        flag: '🇬🇸',
    },
    'Svalbard and Jan Mayen': {
        idd: { root: '+4', suffixes: ['779'] },
        flag: '🇸🇯',
    },
    'Solomon Islands': {
        idd: { root: '+6', suffixes: ['77'] },
        flag: '🇸🇧',
    },
    'Sierra Leone': { idd: { root: '+2', suffixes: ['32'] }, flag: '🇸🇱' },
    'El Salvador': { idd: { root: '+5', suffixes: ['03'] }, flag: '🇸🇻' },
    'San Marino': { idd: { root: '+3', suffixes: ['78'] }, flag: '🇸🇲' },
    Somalia: { idd: { root: '+2', suffixes: ['52'] }, flag: '🇸🇴' },
    'Saint Pierre and Miquelon': {
        idd: { root: '+5', suffixes: ['08'] },
        flag: '🇵🇲',
    },
    Serbia: { idd: { root: '+3', suffixes: ['81'] }, flag: '🇷🇸' },
    'South Sudan': { idd: { root: '+2', suffixes: ['11'] }, flag: '🇸🇸' },
    'São Tomé and Príncipe': {
        idd: { root: '+2', suffixes: ['39'] },
        flag: '🇸🇹',
    },
    Suriname: { idd: { root: '+5', suffixes: ['97'] }, flag: '🇸🇷' },
    Slovakia: { idd: { root: '+4', suffixes: ['21'] }, flag: '🇸🇰' },
    Slovenia: { idd: { root: '+3', suffixes: ['86'] }, flag: '🇸🇮' },
    Sweden: { idd: { root: '+4', suffixes: ['6'] }, flag: '🇸🇪' },
    Eswatini: { idd: { root: '+2', suffixes: ['68'] }, flag: '🇸🇿' },
    'Sint Maarten': {
        idd: { root: '+1', suffixes: ['721'] },
        flag: '🇸🇽',
    },
    Seychelles: { idd: { root: '+2', suffixes: ['48'] }, flag: '🇸🇨' },
    Syria: { idd: { root: '+9', suffixes: ['63'] }, flag: '🇸🇾' },
    'Turks and Caicos Islands': {
        idd: { root: '+1', suffixes: ['649'] },
        flag: '🇹🇨',
    },
    Chad: { idd: { root: '+2', suffixes: ['35'] }, flag: '🇹🇩' },
    Togo: { idd: { root: '+2', suffixes: ['28'] }, flag: '🇹🇬' },
    Thailand: { idd: { root: '+6', suffixes: ['6'] }, flag: '🇹🇭' },
    Tajikistan: { idd: { root: '+9', suffixes: ['92'] }, flag: '🇹🇯' },
    Tokelau: { idd: { root: '+6', suffixes: ['90'] }, flag: '🇹🇰' },
    Turkmenistan: { idd: { root: '+9', suffixes: ['93'] }, flag: '🇹🇲' },
    'Timor-Leste': { idd: { root: '+6', suffixes: ['70'] }, flag: '🇹🇱' },
    Tonga: { idd: { root: '+6', suffixes: ['76'] }, flag: '🇹🇴' },
    'Trinidad and Tobago': {
        idd: { root: '+1', suffixes: ['868'] },
        flag: '🇹🇹',
    },
    Tunisia: { idd: { root: '+2', suffixes: ['16'] }, flag: '🇹🇳' },
    Turkey: { idd: { root: '+9', suffixes: ['0'] }, flag: '🇹🇷' },
    Tuvalu: { idd: { root: '+6', suffixes: ['88'] }, flag: '🇹🇻' },
    Taiwan: { idd: { root: '+8', suffixes: ['86'] }, flag: '🇹🇼' },
    Tanzania: { idd: { root: '+2', suffixes: ['55'] }, flag: '🇹🇿' },
    Uganda: { idd: { root: '+2', suffixes: ['56'] }, flag: '🇺🇬' },
    Ukraine: { idd: { root: '+3', suffixes: ['80'] }, flag: '🇺🇦' },
    'United States Minor Outlying Islands': {
        idd: { root: '+2', suffixes: ['68'] },
        flag: '🇺🇲',
    },
    Uruguay: { idd: { root: '+5', suffixes: ['98'] }, flag: '🇺🇾' },
    'United States': {
        idd: {
            root: '+1',
            suffixes: [
                '201',
                '202',
                '203',
                '205',
                '206',
                '207',
                '208',
                '209',
                '210',
                '212',
                '213',
                '214',
                '215',
                '216',
                '217',
                '218',
                '219',
                '220',
                '224',
                '225',
                '227',
                '228',
                '229',
                '231',
                '234',
                '239',
                '240',
                '248',
                '251',
                '252',
                '253',
                '254',
                '256',
                '260',
                '262',
                '267',
                '269',
                '270',
                '272',
                '274',
                '276',
                '281',
                '283',
                '301',
                '302',
                '303',
                '304',
                '305',
                '307',
                '308',
                '309',
                '310',
                '312',
                '313',
                '314',
                '315',
                '316',
                '317',
                '318',
                '319',
                '320',
                '321',
                '323',
                '325',
                '327',
                '330',
                '331',
                '334',
                '336',
                '337',
                '339',
                '346',
                '347',
                '351',
                '352',
                '360',
                '361',
                '364',
                '380',
                '385',
                '386',
                '401',
                '402',
                '404',
                '405',
                '406',
                '407',
                '408',
                '409',
                '410',
                '412',
                '413',
                '414',
                '415',
                '417',
                '419',
                '423',
                '424',
                '425',
                '430',
                '432',
                '434',
                '435',
                '440',
                '442',
                '443',
                '447',
                '458',
                '463',
                '464',
                '469',
                '470',
                '475',
                '478',
                '479',
                '480',
                '484',
                '501',
                '502',
                '503',
                '504',
                '505',
                '507',
                '508',
                '509',
                '510',
                '512',
                '513',
                '515',
                '516',
                '517',
                '518',
                '520',
                '530',
                '531',
                '534',
                '539',
                '540',
                '541',
                '551',
                '559',
                '561',
                '562',
                '563',
                '564',
                '567',
                '570',
                '571',
                '573',
                '574',
                '575',
                '580',
                '585',
                '586',
                '601',
                '602',
                '603',
                '605',
                '606',
                '607',
                '608',
                '609',
                '610',
                '612',
                '614',
                '615',
                '616',
                '617',
                '618',
                '619',
                '620',
                '623',
                '626',
                '628',
                '629',
                '630',
                '631',
                '636',
                '641',
                '646',
                '650',
                '651',
                '657',
                '660',
                '661',
                '662',
                '667',
                '669',
                '678',
                '681',
                '682',
                '701',
                '702',
                '703',
                '704',
                '706',
                '707',
                '708',
                '712',
                '713',
                '714',
                '715',
                '716',
                '717',
                '718',
                '719',
                '720',
                '724',
                '725',
                '727',
                '730',
                '731',
                '732',
                '734',
                '737',
                '740',
                '743',
                '747',
                '754',
                '757',
                '760',
                '762',
                '763',
                '765',
                '769',
                '770',
                '772',
                '773',
                '774',
                '775',
                '779',
                '781',
                '785',
                '786',
                '801',
                '802',
                '803',
                '804',
                '805',
                '806',
                '808',
                '810',
                '812',
                '813',
                '814',
                '815',
                '816',
                '817',
                '818',
                '828',
                '830',
                '831',
                '832',
                '843',
                '845',
                '847',
                '848',
                '850',
                '854',
                '856',
                '857',
                '858',
                '859',
                '860',
                '862',
                '863',
                '864',
                '865',
                '870',
                '872',
                '878',
                '901',
                '903',
                '904',
                '906',
                '907',
                '908',
                '909',
                '910',
                '912',
                '913',
                '914',
                '915',
                '916',
                '917',
                '918',
                '919',
                '920',
                '925',
                '928',
                '929',
                '930',
                '931',
                '934',
                '936',
                '937',
                '938',
                '940',
                '941',
                '947',
                '949',
                '951',
                '952',
                '954',
                '956',
                '959',
                '970',
                '971',
                '972',
                '973',
                '975',
                '978',
                '979',
                '980',
                '984',
                '985',
                '989',
            ],
        },
        flag: '🇺🇸',
    },
    Uzbekistan: { idd: { root: '+9', suffixes: ['98'] }, flag: '🇺🇿' },
    'Vatican City': {
        idd: { root: '+3', suffixes: ['906698', '79'] },
        flag: '🇻🇦',
    },
    'Saint Vincent and the Grenadines': {
        idd: { root: '+1', suffixes: ['784'] },
        flag: '🇻🇨',
    },
    Venezuela: { idd: { root: '+5', suffixes: ['8'] }, flag: '🇻🇪' },
    'British Virgin Islands': {
        idd: { root: '+1', suffixes: ['284'] },
        flag: '🇻🇬',
    },
    'United States Virgin Islands': {
        idd: { root: '+1', suffixes: ['340'] },
        flag: '🇻🇮',
    },
    Vietnam: { idd: { root: '+8', suffixes: ['4'] }, flag: '🇻🇳' },
    Vanuatu: { idd: { root: '+6', suffixes: ['78'] }, flag: '🇻🇺' },
    'Wallis and Futuna': {
        idd: { root: '+6', suffixes: ['81'] },
        flag: '🇼🇫',
    },
    Samoa: { idd: { root: '+6', suffixes: ['85'] }, flag: '🇼🇸' },
    Yemen: { idd: { root: '+9', suffixes: ['67'] }, flag: '🇾🇪' },
    'South Africa': { idd: { root: '+2', suffixes: ['7'] }, flag: '🇿🇦' },
    Zambia: { idd: { root: '+2', suffixes: ['60'] }, flag: '🇿🇲' },
    Zimbabwe: { idd: { root: '+2', suffixes: ['63'] }, flag: '🇿🇼' },
};
