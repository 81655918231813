export default {
    sectionTitle: {
        fr: '',
        en: 'Accomplished teleconsultations',
    },
    title: {
        fr: 'Historique des téléconsultations',
        en: 'Teleconsultations history',
    },
    duration: {
        fr: 'Durée (minutes)',
        en: 'Duration (minutes)',
    },
    cPurpose: {
        fr: 'Motif de consultation',
        en: 'Reason for consultation',
    },
    emptyList: {
        fr: 'Vous n’avez effectué aucune téléconsultation.',
        en: "You don't have any accomplished teleconsultation.",
    },
    doctor: {
        fr: 'Médecin',
        en: 'Doctor',
    },
};
