import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Typography } from 'antd';
import { getLang } from '../../config/helpers';
import TEXTS from './_texts/ImagesPermission.texts';

const { Paragraph } = Typography;
const lang = getLang();

const ImagePermission = ({ granted, setGranted }) => (
    <div>
        <Paragraph>{TEXTS.paragraph[lang]}</Paragraph>
        <Switch
            checkedChildren={TEXTS.yes[lang]}
            unCheckedChildren={TEXTS.no[lang]}
            checked={granted}
            onChange={setGranted}
        />
    </div>
);


ImagePermission.propTypes = {
    granted: PropTypes.bool.isRequired,
    setGranted: PropTypes.func.isRequired,
};

export default ImagePermission;
