/* eslint-disable no-underscore-dangle */
import { message } from 'antd';
import * as ConsultationsAPI from '../../../api/consultations';
import { sessionExpired } from '../auth';
import TEXTS from './_texts/index.texts';
import { getLang } from '../../../config/helpers';

const lang = getLang();

export const SET_PUBLIC_CONSULTATIONS_LIST = 'SET_PUBLIC_CONSULTATIONS_LIST';
export const SET_CONSULTATIONS_LIST = 'SET_CONSULTATIONS_LIST';
export const SET_TELEC_HISTORY = 'SET_TELEC_HISTORY';
export const REMOVE_CONSULTATION = 'REMOVE_CONSULTATION';
export const SELECT_EVENT = 'SELECT_EVENT';
export const SET_ACTIVE_TELEC = 'SET_ACTIVE_TELEC';
export const SET_STARTING_NOW = 'SET_STARTING_NOW';

const setConsultationsList = value => ({
    type: SET_CONSULTATIONS_LIST,
    payload: value,
});

const setTelecHistory = value => ({
    type: SET_TELEC_HISTORY,
    payload: value,
});

const setPublicEvents = value => ({
    type: SET_PUBLIC_CONSULTATIONS_LIST,
    payload: value,
});

const removeConsultation = id => ({
    type: REMOVE_CONSULTATION,
    payload: id,
});

const selectEvent = value => ({
    type: SELECT_EVENT,
    payload: value,
});

const setActiveTelec = value => ({
    type: SET_ACTIVE_TELEC,
    payload: value,
});

const setStartingNow = value => ({
    type: SET_STARTING_NOW,
    payload: value,
});

const setPublicEventsAsync = () => async (dispatch) => {
    try {
        const res = await ConsultationsAPI.getAll();
        dispatch(setPublicEvents(res.data));
    } catch (err) {
        sessionExpired(err, dispatch);
    }
};

const setConsultationsListAsync = () => async (dispatch) => {
    try {
        const res = await ConsultationsAPI.getAllByUser();
        dispatch(setConsultationsList(res.data));
    } catch (err) {
        sessionExpired(err, dispatch);
    }
};

const removeConsultationAsync = id => async (dispatch) => {
    try {
        const res = await ConsultationsAPI.remove(id);
        if (res.data) {
            dispatch(removeConsultation(id));
            message.success(TEXTS.removeSuccessMSG[lang]);
        }
    } catch (err) {
        sessionExpired(err, dispatch);
    }
};

const createSetupIntentAsync = (id, body) => async (dispatch) => {
    try {
        const res = await ConsultationsAPI.createSetupIntent(id, body);
        return res.data;
    } catch (err) {
        if (err.response && err.response.status === 401) {
            return 401;
        }
        sessionExpired(err, dispatch);
        return null;
    }
};

const getTelecHistoryAsync = () => async (dispatch) => {
    try {
        const res = await ConsultationsAPI.getHistory();
        dispatch(setTelecHistory(res.data));
    } catch (err) {
        sessionExpired(err, dispatch);
    }
};

export {
    setConsultationsList,
    setConsultationsListAsync,
    removeConsultation,
    removeConsultationAsync,
    setPublicEvents,
    setPublicEventsAsync,
    selectEvent,
    createSetupIntentAsync,
    setActiveTelec,
    setStartingNow,
    setTelecHistory,
    getTelecHistoryAsync,
};
